var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "top-tip" }, [
        _c(
          "div",
          { staticClass: "selected-wrapper" },
          [
            _vm.hasSelected
              ? _c("span", [
                  _vm._v(" 已选中 "),
                  _c("strong", [_vm._v(_vm._s(_vm.selectedRowKeys.length))]),
                  _vm._v(" 项任务 "),
                ])
              : _vm._e(),
            _vm.hasSelected
              ? _c(
                  "a-dropdown",
                  [
                    _c(
                      "a",
                      {
                        staticClass: "batch-operate-link",
                        on: {
                          click: function (e) {
                            return e.preventDefault()
                          },
                        },
                      },
                      [
                        _c("a-icon", { attrs: { type: "edit" } }),
                        _vm._v("批量操作 "),
                      ],
                      1
                    ),
                    _vm.currentActiveKey === "FG"
                      ? _c(
                          "a-menu",
                          { attrs: { slot: "overlay" }, slot: "overlay" },
                          [
                            _c("a-menu-item", [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleBatchCreateCard("rework")
                                    },
                                  },
                                },
                                [_vm._v("批量打印返工处置通知单")]
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.currentActiveKey === "BF"
                      ? _c(
                          "a-menu",
                          { attrs: { slot: "overlay" }, slot: "overlay" },
                          [
                            _c("a-menu-item", [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleBatchCreateCard("scrap")
                                    },
                                  },
                                },
                                [_vm._v("批量打印产品报废单")]
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.currentActiveKey === "TH"
                      ? _c(
                          "a-menu",
                          { attrs: { slot: "overlay" }, slot: "overlay" },
                          [
                            _c("a-menu-item", [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleBatchCreateReturnCard(
                                        _vm.selectedRowKeys
                                      )
                                    },
                                  },
                                },
                                [_vm._v("生成退库交接单")]
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.currentActiveKey === "CC"
                      ? _c(
                          "a-menu",
                          { attrs: { slot: "overlay" }, slot: "overlay" },
                          [
                            _c("a-menu-item", [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleBatchCreateCard(
                                        "overTolerance"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("批量打印超差处置单")]
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c(
        "a-tabs",
        {
          attrs: { "active-key": _vm.currentActiveKey, type: "card" },
          on: { change: _vm.handleTabChange },
        },
        [
          _c("a-tab-pane", { key: "FG", attrs: { tab: "返工返修" } }),
          _c("a-tab-pane", { key: "BF", attrs: { tab: "生产报废" } }),
          _c("a-tab-pane", { key: "TH", attrs: { tab: "退货统计" } }),
          _c("a-tab-pane", { key: "CC", attrs: { tab: "超差处理" } }),
        ],
        1
      ),
      _c(
        "a-table",
        {
          key: _vm.tableKey,
          staticClass: "plans-table",
          attrs: {
            "row-key": "id",
            "row-selection": {
              selectedRowKeys: _vm.selectedRowKeys,
              onChange: _vm.onSelectChange,
              getCheckboxProps: _vm.inventoryRowSelection.getCheckboxProps,
            },
            columns: _vm.columns,
            "data-source": _vm.qualities,
            loading: _vm.loading,
            pagination: _vm.pagination,
            bordered: "",
            scroll: { x: 3000, y: 590 },
          },
          on: { change: _vm.handleTableChange },
          scopedSlots: _vm._u(
            [
              {
                key: "filterDropdown",
                fn: function (ref) {
                  var setSelectedKeys = ref.setSelectedKeys
                  var selectedKeys = ref.selectedKeys
                  var confirm = ref.confirm
                  var clearFilters = ref.clearFilters
                  var column = ref.column
                  return _c(
                    "div",
                    { staticStyle: { padding: "8px" } },
                    [
                      column.searchType === "text"
                        ? _c("a-input", {
                            directives: [
                              {
                                name: "ant-ref",
                                rawName: "v-ant-ref",
                                value: function (c) {
                                  return (_vm.searchInput = c)
                                },
                                expression: "(c) => (searchInput = c)",
                              },
                            ],
                            staticStyle: {
                              width: "188px",
                              "margin-bottom": "8px",
                              display: "block",
                            },
                            attrs: {
                              placeholder: "搜索 " + column.title,
                              value: selectedKeys[0],
                            },
                            on: {
                              change: function (e) {
                                return setSelectedKeys(
                                  e.target.value ? [e.target.value] : []
                                )
                              },
                              pressEnter: function () {
                                return _vm.handleSearch(
                                  selectedKeys,
                                  confirm,
                                  column.key
                                )
                              },
                            },
                          })
                        : _vm._e(),
                      column.searchType === "number"
                        ? _c(
                            "div",
                            [
                              _c("a-input-number", {
                                staticStyle: {
                                  width: "95px",
                                  "margin-bottom": "8px",
                                  display: "inline-block",
                                },
                                attrs: {
                                  size: "small",
                                  placeholder: "最小值",
                                  min: 0,
                                  max: 999999,
                                },
                                on: {
                                  change: function (e) {
                                    return setSelectedKeys([e, selectedKeys[1]])
                                  },
                                  pressEnter: function () {
                                    return _vm.handleSearch(
                                      selectedKeys,
                                      confirm,
                                      column.key
                                    )
                                  },
                                },
                                model: {
                                  value: selectedKeys[0],
                                  callback: function ($$v) {
                                    _vm.$set(selectedKeys, 0, $$v)
                                  },
                                  expression: "selectedKeys[0]",
                                },
                              }),
                              _c("span", [_vm._v("-")]),
                              _c("a-input-number", {
                                staticStyle: {
                                  width: "95px",
                                  "margin-bottom": "8px",
                                  display: "inline-block",
                                },
                                attrs: {
                                  size: "small",
                                  placeholder: "最大值",
                                  min: selectedKeys[0],
                                  max: 999999,
                                },
                                on: {
                                  change: function (e) {
                                    return setSelectedKeys([selectedKeys[0], e])
                                  },
                                  pressEnter: function () {
                                    return _vm.handleSearch(
                                      selectedKeys,
                                      confirm,
                                      column.key
                                    )
                                  },
                                },
                                model: {
                                  value: selectedKeys[1],
                                  callback: function ($$v) {
                                    _vm.$set(selectedKeys, 1, $$v)
                                  },
                                  expression: "selectedKeys[1]",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      column.searchType === "date"
                        ? _c(
                            "div",
                            [
                              _c("a-range-picker", {
                                staticStyle: {
                                  width: "330px",
                                  "margin-bottom": "8px",
                                },
                                attrs: {
                                  "show-time": { format: "HH:mm:ss" },
                                  size: "small",
                                  ranges: {
                                    今天: [_vm.$moment(), _vm.$moment()],
                                    本月: [
                                      _vm.$moment().startOf("month"),
                                      _vm.$moment().endOf("month"),
                                    ],
                                  },
                                },
                                on: {
                                  change: function (dates, dateStrings) {
                                    return setSelectedKeys(
                                      [].concat(dateStrings)
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "a-button",
                        {
                          staticStyle: { width: "90px", "margin-right": "8px" },
                          attrs: {
                            type: "primary",
                            icon: "search",
                            size: "small",
                          },
                          on: {
                            click: function () {
                              return _vm.handleSearch(
                                selectedKeys,
                                confirm,
                                column.key
                              )
                            },
                          },
                        },
                        [_vm._v("搜索")]
                      ),
                      _c(
                        "a-button",
                        {
                          staticStyle: { width: "90px" },
                          attrs: { size: "small" },
                          on: {
                            click: function () {
                              return _vm.handleReset(
                                selectedKeys,
                                clearFilters,
                                column.key
                              )
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                    ],
                    1
                  )
                },
              },
              {
                key: "filterIcon",
                fn: function (filtered) {
                  return _c("a-icon", {
                    style: { color: filtered ? "#108ee9" : undefined },
                    attrs: { type: "search" },
                  })
                },
              },
              {
                key: "returnReceiptNumber",
                fn: function (text, column) {
                  return [
                    column.returnReceiptNumber
                      ? [
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.hadnleCheckCard(column)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(text) + " ")]
                          ),
                        ]
                      : [
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleCreateSingleCard(column)
                                },
                              },
                            },
                            [_vm._v("点击生成")]
                          ),
                        ],
                  ]
                },
              },
              {
                key: "defeatReceiptNumber",
                fn: function (text, column) {
                  return [
                    column.defeatReceiptNumber
                      ? [
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleJumpAbandonCard(column)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(text) + " ")]
                          ),
                        ]
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "hasAction",
                fn: function (text) {
                  return _c(
                    "div",
                    {},
                    [
                      text === "Y"
                        ? _c("a-tag", { attrs: { color: "blue" } }, [
                            _vm._v("需要"),
                          ])
                        : _vm._e(),
                      text === "N"
                        ? _c("a-tag", { attrs: { color: "red" } }, [
                            _vm._v("不需要"),
                          ])
                        : _vm._e(),
                    ],
                    1
                  )
                },
              },
              {
                key: "status",
                fn: function (text) {
                  return _c(
                    "div",
                    {},
                    [
                      text === "CNL"
                        ? _c("a-tag", { attrs: { color: "red" } }, [
                            _vm._v("已撤销"),
                          ])
                        : _vm._e(),
                      text === "NML"
                        ? _c("a-tag", { attrs: { color: "green" } }, [
                            _vm._v("正常"),
                          ])
                        : _vm._e(),
                    ],
                    1
                  )
                },
              },
              {
                key: "actualUseMaterialList",
                fn: function (text) {
                  return _c(
                    "div",
                    {},
                    [
                      _c(
                        "a-tooltip",
                        [
                          _c(
                            "template",
                            { slot: "title" },
                            _vm._l(text, function (item, index) {
                              return _c("span", { key: index }, [
                                _vm._v(
                                  " " +
                                    _vm._s(item.materialName) +
                                    "(" +
                                    _vm._s(item.materialCode) +
                                    ") "
                                ),
                                index < text.length - 1
                                  ? _c("span", [_vm._v(",")])
                                  : _vm._e(),
                              ])
                            }),
                            0
                          ),
                          _c(
                            "div",
                            { staticClass: "table-ellis" },
                            _vm._l(text, function (item, index) {
                              return _c("span", { key: index }, [
                                _vm._v(
                                  _vm._s(item.materialName) +
                                    "(" +
                                    _vm._s(item.materialCode) +
                                    "),"
                                ),
                              ])
                            }),
                            0
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                },
              },
              {
                key: "defeatDemand",
                fn: function (text, column) {
                  return _vm.currentActiveKey === "BF"
                    ? _c(
                        "div",
                        {},
                        [
                          text
                            ? _c("a-tag", { attrs: { color: "green" } }, [
                                _vm._v("已下达"),
                              ])
                            : column.status === "NML"
                            ? _c(
                                "el-link",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleCreateTask(column)
                                    },
                                  },
                                },
                                [_vm._v("点击下达")]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e()
                },
              },
              {
                key: "redone-repairEffect",
                fn: function (text) {
                  return _vm.currentActiveKey === "FG"
                    ? _c(
                        "div",
                        {},
                        [
                          text === "OK"
                            ? _c("a-tag", { attrs: { color: "blue" } }, [
                                _vm._v("合格"),
                              ])
                            : _vm._e(),
                          text === "RE"
                            ? _c("a-tag", { attrs: { color: "red" } }, [
                                _vm._v("再次返修"),
                              ])
                            : _vm._e(),
                          text === "BF"
                            ? _c("a-tag", { attrs: { color: "yellow" } }, [
                                _vm._v("报废"),
                              ])
                            : _vm._e(),
                          text === "CC"
                            ? _c("a-tag", { attrs: { color: "yellow" } }, [
                                _vm._v("超差"),
                              ])
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e()
                },
              },
              {
                key: "actionEffect",
                fn: function (text) {
                  return _vm.currentActiveKey === "TH"
                    ? _c(
                        "div",
                        {},
                        [
                          text === "OK"
                            ? _c("a-tag", { attrs: { color: "green" } }, [
                                _vm._v("合格"),
                              ])
                            : _vm._e(),
                          text === "BF"
                            ? _c("a-tag", { attrs: { color: "red" } }, [
                                _vm._v("报废"),
                              ])
                            : _vm._e(),
                          text === "CC"
                            ? _c("a-tag", { attrs: { color: "yellow" } }, [
                                _vm._v("超差"),
                              ])
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e()
                },
              },
              {
                key: "repairEffect",
                fn: function (text) {
                  return _vm.currentActiveKey === "TH"
                    ? _c(
                        "div",
                        {},
                        [
                          text === "FX"
                            ? _c("a-tag", { attrs: { color: "blue" } }, [
                                _vm._v("返修"),
                              ])
                            : _vm._e(),
                          text === "BT"
                            ? _c("a-tag", { attrs: { color: "red" } }, [
                                _vm._v("报废"),
                              ])
                            : _vm._e(),
                          text === "TH"
                            ? _c("a-tag", { attrs: { color: "orange" } }, [
                                _vm._v("退回客户"),
                              ])
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e()
                },
              },
              {
                key: "taskCode",
                fn: function (text, column) {
                  return _c("div", {}, [
                    _c(
                      "a",
                      {
                        staticStyle: { color: "#409eff" },
                        attrs: { href: "#", title: "点击查看缺陷详情" },
                        on: {
                          click: function ($event) {
                            return _vm.handleCheckDetail(column)
                          },
                        },
                      },
                      [_vm._v(_vm._s(text))]
                    ),
                  ])
                },
              },
              {
                key: "defeatType",
                fn: function (text) {
                  return _c(
                    "div",
                    {},
                    [_c("a-tag", [_vm._v(_vm._s(_vm.typeMap[text]))])],
                    1
                  )
                },
              },
              {
                key: "taskStatus",
                fn: function (text) {
                  return _c(
                    "div",
                    {},
                    [
                      text === 0
                        ? [
                            _c("a-badge", { attrs: { status: "default" } }),
                            _vm._v("准备中 "),
                          ]
                        : _vm._e(),
                      text === 1
                        ? [
                            _c("a-badge", { attrs: { status: "default" } }),
                            _vm._v("未开始 "),
                          ]
                        : _vm._e(),
                      text === 2
                        ? [
                            _c("a-badge", { attrs: { status: "processing" } }),
                            _vm._v("进行中 "),
                          ]
                        : _vm._e(),
                      text === 3
                        ? [
                            _c("a-badge", { attrs: { status: "success" } }),
                            _vm._v("已完成 "),
                          ]
                        : _vm._e(),
                      text === 9
                        ? [
                            _c("a-badge", { attrs: { status: "warning" } }),
                            _vm._v("暂停中 "),
                          ]
                        : _vm._e(),
                      text === 8
                        ? [
                            _c("a-badge", { attrs: { status: "error" } }),
                            _vm._v("已取消 "),
                          ]
                        : _vm._e(),
                      text === 10
                        ? [
                            _c("a-badge", { attrs: { status: "error" } }),
                            _vm._v("异常终止 "),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                },
              },
              {
                key: "operation",
                fn: function (text, column) {
                  return (
                    _vm.currentActiveKey === "BF"
                      ? column.status === "NML"
                      : true
                  )
                    ? _c(
                        "span",
                        { staticClass: "table-operation" },
                        [
                          _c(
                            "a-dropdown",
                            { attrs: { placement: "topRight" } },
                            [
                              _c(
                                "a-menu",
                                {
                                  staticClass: "ant-dropdown-link",
                                  attrs: { slot: "overlay" },
                                  slot: "overlay",
                                },
                                [
                                  _c(
                                    "a-menu-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleUpdateQualities(
                                            column
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("更新记录")]
                                  ),
                                  _vm.currentActiveKey === "BF" &&
                                  column.status === "NML"
                                    ? _c(
                                        "a-menu-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleRevertModal(
                                                column
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("撤销")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "a",
                                [
                                  _vm._v(" 操作 "),
                                  _c("a-icon", { attrs: { type: "down" } }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e()
                },
              },
            ],
            null,
            true
          ),
        },
        [
          _c(
            "div",
            { attrs: { slot: "generateTimeTitle" }, slot: "generateTimeTitle" },
            [
              _vm.currentActiveKey === "FG" ? [_vm._v("返工日期")] : _vm._e(),
              _vm.currentActiveKey === "BF" ? [_vm._v("报废日期")] : _vm._e(),
              _vm.currentActiveKey === "TH" ? [_vm._v("退货日期")] : _vm._e(),
              _vm.currentActiveKey === "CC" ? [_vm._v("超差日期")] : _vm._e(),
            ],
            2
          ),
        ]
      ),
      _c(
        "el-dropdown",
        {
          staticClass: "export-record",
          on: { command: _vm.handleExportRecordCommand },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                loading: _vm.exportLoading,
                size: "medium",
                type: "primary",
                icon: "el-icon-download",
              },
            },
            [_vm._v("导出质量记录")]
          ),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c("el-dropdown-item", { attrs: { command: "FILTERED" } }, [
                _vm._v("导出当前筛选记录"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.qualitiesEditModalVisible
        ? _c("QualitiesEditModal", {
            attrs: { type: _vm.currentEditType, record: _vm.currentRecord },
            on: {
              cancel: _vm.handleUpdateQualitiesCancel,
              confirm: _vm.handleUpdateQualitiesConfirm,
            },
          })
        : _vm._e(),
      _vm.modalVisible
        ? _c("CreateReturnCardModal", {
            attrs: { "records-data": _vm.recordIds },
            on: {
              cancel: _vm.handleInventoryRecordsModalCanceled,
              confirm: _vm.handleInventoryRecordsModalConfirmed,
            },
          })
        : _vm._e(),
      _vm.editTaskModalVisible
        ? _c("TaskEdit", {
            attrs: {
              "modal-title": _vm.currentEditType,
              "task-form": _vm.currentTask,
              "current-task-type": "BF",
            },
            on: {
              cancel: _vm.handleTaskModalCanceled,
              confirm: _vm.handleTaskModalConfirmed,
            },
          })
        : _vm._e(),
      _vm.revertModalVisible
        ? _c("RevertModal", {
            attrs: { record: _vm.revertRecord },
            on: {
              cancel: _vm.revertModalCancel,
              confirm: _vm.revertModalConfirm,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }