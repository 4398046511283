<template>
    <a-modal class="modal-container" :visible="true" title="撤销确认" :maskClosable="false" @cancel="handleCancel"
        width="600px">
        <template slot="footer">
            <a-button @click="handleReset">
                重置
            </a-button>
            <a-button @click="handleCancel">
                取消
            </a-button>
            <a-button type="primary" :loading="loading" @click="handleConfirm">
                确定
            </a-button>
        </template>
        <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-model-item label="单号：">
                <div>{{ this.record.defeatReceiptNumber }}</div>
            </a-form-model-item>
            <a-form-model-item label="撤销原因" prop="changeReason">
                <a-textarea style="width: 460px" placeholder='请输入撤销原因' v-model.trim="form.changeReason"
                    :auto-size="{ minRows: 3, maxRows: 5 }" />
            </a-form-model-item>
        </a-form-model>
    </a-modal>
</template>

<script>
import { revertQuality } from '@/api/qualities';

export default {
    props: {
        record: Object
    },
    data() {
        return {
            labelCol: {
                span: 5
            },
            wrapperCol: {
                span: 15,
                offset: 1
            },
            loading: false,
            form: {
                changeReason: null,
            },
            rules: {
                changeReason: [
                    { required: true, message: '请输入', trigger: 'blur' },
                    { min: 1, max: 500, message: '请输入 500 字以内', trigger: 'blur' },
                ],
            },
        }
    },
    methods: {
        handleReset() {
            this.$refs.ruleForm.resetFields();
        },
        handleCancel() {
            this.$emit('cancel');
        },
        handleConfirm() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.returnSettlement();
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        returnSettlement() {
            this.loading = true;
            revertQuality(
                this.record.id,
                {
                    changeReason: this.form.changeReason
                }).then(() => {
                    this.$message.success('撤销成功')
                    this.$emit('confirm');
                }).catch(
                    (reason) => {
                        this.loading = false;
                        console.log('处理失败的 promise (' + reason + ')');
                    }).finally(() => {
                        this.loading = false
                    });
        },
    }
}

</script>

<style scoped></style>