<template>
  <div class="card-container">
    <div id="craft-card-print">
      <template v-if="receipt <= 1">
        <div style="page-break-after:always">
          <div class="title">
            <div class="middle">重庆市DBR刀具有限公司产品{{ type }}</div>
          </div>
          <a-descriptions bordered>
            <a-descriptions-item label="客户名称">{{ ifClientVisible ? data.client : '' }}</a-descriptions-item>
            <a-descriptions-item label="出库单单号" span="2">{{ data.takeOutNumber }}</a-descriptions-item>
            <a-descriptions-item label="客户合同编号" span="1">{{ data.clientContractCode }}</a-descriptions-item>
            <a-descriptions-item label="客户订单编号" span="2">{{ data.clientOrderCode }}</a-descriptions-item>
            <a-descriptions-item label="IMES订单编号" span="3">{{ data.orderCode }}</a-descriptions-item>
            <a-descriptions-item label="地址">重XXXXXXXXX</a-descriptions-item>
            <a-descriptions-item label="电话（传真）">023-XXXXXXXXX XXXXX</a-descriptions-item>
          </a-descriptions>
          <a-table :columns="columns" :data-source="productTakeOutItemList" bordered :pagination="false">
            <p slot="amount" slot-scope="text" class="amount-font">{{ text }}</p>
            <div slot="customPriceTitle">
              <template v-if="withTax">金额(含税)</template>
              <template v-else>金额(无税)</template>
            </div>
            <div slot="customUnitPriceTitle">
              <template v-if="withTax">单价(含税)</template>
              <template v-else>单价(无税)</template>
            </div>
            <div slot="taxFreePrice" slot-scope="text, column">
              <template v-if="ifAccountVisible">
                <template v-if="withTax">{{ column.taxPrice }}</template>
                <template v-else>{{ column.taxFreePrice }}</template>
              </template>
              <template v-else-if="text">***</template>
            </div>
            <div slot="taxFreeUnitPrice" slot-scope="text, column">
              <template v-if="ifAccountVisible">
                <template v-if="withTax">{{ column.taxUnitPrice }}</template>
                <template v-else>{{ text }}</template>
              </template>
              <template v-else-if="text">***</template>
            </div>
          </a-table>
          <a-descriptions bordered>
            <a-descriptions-item label="结算方式">
              <span class="settle-type">
                <a-icon type="border" style="display: inline-block;margin-right: 3px;" />挂账
              </span>
              <span class="settle-type">
                <a-icon type="border" style="display: inline-block;margin-right: 3px;" />现款
              </span>
              <span class="settle-type">
                <a-icon type="border" style="display: inline-block;margin-right: 3px;" />免费
              </span>
            </a-descriptions-item>
            <a-descriptions-item label="数量合计">
              <span>{{ data.totalProductNum }}</span>
            </a-descriptions-item>
            <a-descriptions-item v-if="ifAccountVisible && !withTax" label="金额(无税)合计">
              <span>{{ data.totalTaxFreePrice }}</span>
            </a-descriptions-item>
            <a-descriptions-item v-if="ifAccountVisible && withTax" label="金额(含税)合计">
              <span>{{ data.totalTaxPrice }}</span>
            </a-descriptions-item>
            <a-descriptions-item v-if="!ifAccountVisible" label="金额合计">
              <span>***</span>
            </a-descriptions-item>
          </a-descriptions>
          <div class="footer-form">
            <span>制单人：{{ data.takeOutReceiptUser }}</span>
            <div class="bottom-line" />
            <span>送货人：</span>
            <div class="bottom-line" />
            <span>库房：</span>
            <div class="bottom-line" />
            <span>收货人：</span>
            <div class="bottom-line" />
            <span style="float: right">{{ data.takeOutTime }}</span>
          </div>
        </div>
      </template>
      <template v-else>
        <div v-for="item of data" :key="item.takeOutNumber" class="page" style="page-break-after:always">
          <div class="title">
            <div class="middle">重庆市DBR刀具有限公司产品{{ item.type }}</div>
          </div>
          <a-descriptions bordered>
            <a-descriptions-item label="客户名称">{{ item.client }}</a-descriptions-item>
            <a-descriptions-item label="出库单单号" span="2">{{ item.takeOutNumber }}</a-descriptions-item>
            <a-descriptions-item label="客户合同编号" span="1">{{ item.clientContractCode }}</a-descriptions-item>
            <a-descriptions-item label="客户订单编号" span="2">{{ item.clientOrderCode }}</a-descriptions-item>
            <a-descriptions-item label="IMES订单编号" span="3">{{ item.orderCode }}</a-descriptions-item>
            <a-descriptions-item label="地址">重XXXXXXXXX</a-descriptions-item>
            <a-descriptions-item label="电话（传真）">023-XXXXXXXXX XXXXX</a-descriptions-item>
          </a-descriptions>
          <a-table :columns="columns" :data-source="item.productTakeOutItemList" bordered :pagination="false">
            <p slot="amount" slot-scope="text" class="amount-font">{{ text }}</p>
            <div slot="customPriceTitle">
              <template v-if="withTax">金额(含税)</template>
              <template v-else>金额(无税)</template>
            </div>
            <div slot="customUnitPriceTitle">
              <template v-if="withTax">单价(含税)</template>
              <template v-else>单价(无税)</template>
            </div>
            <div slot="taxFreePrice" slot-scope="text, column">
              <template v-if="ifAccountVisible">
                <template v-if="withTax">{{ column.taxPrice }}</template>
                <template v-else>{{ column.taxFreePrice }}</template>
              </template>
              <template v-else-if="text">***</template>
            </div>
            <div slot="taxFreeUnitPrice" slot-scope="text, column">
              <template v-if="ifAccountVisible">
                <template v-if="withTax">{{ column.taxUnitPrice }}</template>
                <template v-else>{{ text }}</template>
              </template>
              <template v-else-if="text">***</template>
            </div>
          </a-table>
          <a-descriptions bordered>
            <a-descriptions-item label="结算方式">
              <span class="settle-type">
                <a-icon type="border" style="display: inline-block;margin: 3px;" />挂账
              </span>
              <span class="settle-type">
                <a-icon type="border" style="display: inline-block;margin: 3px;" />现款
              </span>
              <span class="settle-type">
                <a-icon type="border" style="display: inline-block;margin: 3px;" />免费
              </span>
            </a-descriptions-item>
            <a-descriptions-item label="数量合计">
              <span>{{ item.totalProductNum }}</span>
            </a-descriptions-item>
            <a-descriptions-item v-if="ifAccountVisible && !withTax" label="金额(无税)合计">
              <span>{{ item.totalTaxFreePrice }}</span>
            </a-descriptions-item>
            <a-descriptions-item v-if="ifAccountVisible && withTax" label="金额(含税)合计">
              <span>{{ item.totalTaxPrice }}</span>
            </a-descriptions-item>
            <a-descriptions-item v-if="!ifAccountVisible" label="金额合计">
              <span>***</span>
            </a-descriptions-item>
          </a-descriptions>
          <div class="footer-form">
            <span>制单人：{{ item.takeOutReceiptUser }}</span>
            <div class="bottom-line" />
            <span>送货人：</span>
            <div class="bottom-line" />
            <span>库房：</span>
            <div class="bottom-line" />
            <span>收货人：</span>
            <div class="bottom-line" />
            <span>{{ item.takeOutTime }}</span>
          </div>
        </div>
      </template>
    </div>
    <div class="footer">
      <div class="radio">
        是否显示金额
        <a-switch default-checked @change="onSwitchChange" />
      </div>
      <div class="radio">
        是否显示客户名称
        <a-switch default-checked @change="onClientSwitchChange" />
      </div>
      <div v-if="ifAccountVisible" class="type">
        <a-radio-group v-model="withTax" name="radioGroup">
          <a-radio :value="true">含税</a-radio>
          <a-radio :value="false">无税</a-radio>
        </a-radio-group>
      </div>
      <a-button v-print="'craft-card-print'" type="primary" class="btn" block>打印</a-button>
    </div>
  </div>
</template>
<script>
import {
  getOutInventorysCheck,
  getAllOutInventorysCheck,
} from '@/api/inventory'

const columns = [
  {
    title: '序号',
    dataIndex: 'sequenceNum',
    width: 30,
  },
  {
    title: '任务号',
    dataIndex: 'taskCode',
    width: 120,
  },
  {
    title: '名称',
    dataIndex: 'productName',
    width: 250,
  },
  {
    title: '图号',
    dataIndex: 'drawingNum',
    width: 250,
  },
  {
    title: '物料编码',
    dataIndex: 'clientMaterialCode',
    width: 100,
  },
  {
    title: '单位',
    dataIndex: 'unit',
    width: 50,
  },
  {
    title: '交付数量',
    dataIndex: 'amount',
    width: 50,
    scopedSlots: {
      customRender: 'amount',
    },
  },
  {
    title: '未交付数量',
    dataIndex: 'notFinishAmount',
    width: 70,
    scopedSlots: {
      customRender: 'notFinishAmount',
    },
  },
  {
    slots: { title: 'customUnitPriceTitle' },
    dataIndex: 'taxFreeUnitPrice',
    width: 90,
    scopedSlots: {
      customRender: 'taxFreeUnitPrice',
    },
  },
  {
    slots: { title: 'customPriceTitle' },
    dataIndex: 'taxFreePrice',
    width: 90,
    scopedSlots: {
      customRender: 'taxFreePrice',
    },
  },
  {
    title: '客户产品库房编号',
    dataIndex: 'clientWarehouseCode',
    width: 90,
  },
  {
    title: '客户产品计划号',
    dataIndex: 'clientPlanCode',
    width: 90,
  },
  {
    title: '备注',
    dataIndex: 'takeOutComment',
    width: 60,
  },
]
export default {
  data() {
    return {
      columns,
      data: [],
      productTakeOutItemList: [],
      type: '',
      receipt: Number,
      ifAccountVisible: true,
      ifClientVisible: true,
      withTax: false,
    }
  },
  created() {
    const params = this.$route.query
    this.receipt = params.receiptNumber.split(',').length
    if (this.receipt > 1) {
      params.receiptNumbers = params.receiptNumber
      delete params.receiptNumber
      getAllOutInventorysCheck(params).then((res) => {
        if (res.data) {
          this.data = res.data
          this.data.map((item) => {
            switch (item.takeOutType) {
              case 0:
                item.type = '普通出库单'
                break
              case 1:
                item.type = '返修出库单'
                break
              case 2:
                item.type = '其他出库单'
                break
            }
            const len = item.productTakeOutItemList.length
            const _len = 7 - item.productTakeOutItemList.length
            item.productTakeOutItemList.map((val, index) => {
              val.sequenceNum = index + 1
            })
            if (_len > 0) {
              for (let i = 0; i < _len; i++) {
                item.productTakeOutItemList.push({
                  sequenceNum: len + i + 1,
                })
              }
            }
          })
        } else {
          this.$message.error('获取出库单数据失败，请重试！')
        }
      })
    } else {
      getOutInventorysCheck(params).then((res) => {
        if (res.data) {
          this.productTakeOutItemList = res.data.productTakeOutItemList
          const len = this.productTakeOutItemList.length
          const _len = 7 - this.productTakeOutItemList.length

          this.productTakeOutItemList.map((val, index) => {
            val.sequenceNum = index + 1
          })
          if (_len > 0) {
            for (let i = 0; i < _len; i++) {
              this.productTakeOutItemList.push({
                sequenceNum: len + i + 1,
              })
            }
          }
          console.log(this.productTakeOutItemList)
          this.data = res.data
          switch (this.data.takeOutType) {
            case 0:
              this.type = '普通出库单'
              break
            case 1:
              this.type = '返修出库单'
              break
            case 2:
              this.type = '其他出库单'
              break
          }
        } else {
          this.$message.error('获取出库单数据失败，请重试！')
        }
      })
    }
  },
  methods: {
    onSwitchChange(checked) {
      this.ifAccountVisible = checked
    },
    onClientSwitchChange(checked) {
      this.ifClientVisible = checked
    },
  },
}
</script>
<style scoped lang="scss">
.card-container {
  width: 80%;
  margin: auto;
}

.page {
  margin-bottom: 100px;
}

.title {
  height: 50px;
  display: flex;
  justify-content: center;
  font-family: SimHei;
  font-size: 20px;
  line-height: 50px;
  margin-bottom: 8px;
  color: black;
}

.card-title>>>.ant-descriptions-item-label {
  font-family: SimHei;
  font-size: 20px;
}

.amount-font {
  font-family: SimHei;
  font-size: 17px;
  margin: 0;
}

/deep/ .ant-table {
  padding: 16px 0px;
  color: black;
}

/deep/ .ant-descriptions-item-label {
  color: black;
}

/deep/ .ant-descriptions-item-content {
  color: black;
}

/deep/ .ant-descriptions-bordered .ant-descriptions-item-label {
  text-align: center;
  border: 1px solid black;
  padding: 1px 2px;
}

/deep/ .ant-descriptions-bordered .ant-descriptions-item-content {
  text-align: center;
  border: 1px solid black;
  font-family: SimHei;
  font-size: 20px;
  font-weight: bold;
  padding: 1px 2px;
}

/deep/ .ant-table-thead th {
  padding: 2px 2px;
  text-align: center;
  border: 1px solid black;
  color: black;
}

/deep/ .ant-table-tbody {
  font-size: 20px;
}

/deep/ .ant-table-tbody td {
  padding: 0px 0px;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  text-align: center;
}

/deep/ .ant-table-tbody>tr>td:last-child {
  border-right: 1px solid black;
}

/deep/ .ant-descriptions-bordered .ant-descriptions-view {
  border: 0px;
}

/deep/ .ant-table-row {
  // height: 80px !important;
  // 建议使用 vh 进行设置，避免在其他尺寸屏幕不合适
  height: 7vh !important;
  font-family: Arial;
  // font-family: SimHei;
  // font-weight: bold;
}

/deep/ .ant-descriptions-item-label {
  font-family: SimHei;
  font-size: 21px;
  font-weight: bold;
}

/deep/ .ant-table-thead {
  font-family: SimHei;
  font-size: 18px;
  font-weight: bold !important;
  color: black;
}

/deep/ .ant-table-thead>tr>th {
  font-weight: bold !important;
  color: black;
}

/deep/ .ant-table-bordered .ant-table-thead>tr>th,
.ant-table-bordered .ant-table-tbody>tr>td {
  border: 1px solid black;
}

.footer {
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  padding: 0 100px;
  align-items: center;

  .btn {
    width: 120px;
  }

  .radio {
    font-weight: bold;
  }
}

.footer-form {
  font-family: SimHei;
  font-size: 15px;
  font-weight: bold;
  color: black;
}

.bottom-line {
  width: 140px;
  height: 20px;
  display: inline-block;
}

.middle {
  font-family: SimHei;
  font-size: 30px;
  font-weight: bold;
}

.settle-type {
  margin-left: 30px;
  margin-right: 30px;
}
</style>
