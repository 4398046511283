import request from '@/utils/api.request'

/**
 * 分页获取产品信息
 * @param {object} data
 */
export function getQualities(data) {
  return request({
    url: `/qualities`,
    method: 'get',
    params: data,
  })
}

/**
 * 删除指定id的质量记录
 * @param {number} id
 */
export function deleteQuality(id) {
  return request({
    url: `/qualities/${id}`,
    method: 'delete',
  })
}

/**
 * 根据id获取指定的质量信息
 * @param {number} id
 */
export function getQualityById(id) {
  return request({
    url: `/qualities/${id}`,
    method: 'get',
  })
}

/**
 * 更新质量基础信息
 * @param {number} id
 */
export function updateQualityBaseInfo(id, data) {
  return request({
    url: `/qualities/${id}/base-info`,
    method: 'patch',
    data,
  })
}

/**
 * 更新各缺陷类型人员信息
 * @param {number} id
 */
export function updateQualityUserInfo(id, userType, data) {
  return request({
    url: `/qualities/${id}/${userType}/users`,
    method: 'patch',
    data,
  })
}

/**
 * 更新各缺陷类型工序信息
 * @param {number} id
 */
export function updateQualityOperationInfo(id, operationType, data) {
  return request({
    url: `/qualities/${id}/${operationType}/operations`,
    method: 'patch',
    data,
  })
}

/**
 * 更新各缺陷类型文件信息
 * @param {number} id
 */
export function updateQualityFileInfo(id, detailType, data) {
  return request({
    url: `/qualities/${id}/${detailType}/files`,
    method: 'patch',
    data,
  })
}

/**
 * 更新各缺陷根本原因
 * @param {number} id
 */
export function updateQualityReasonInfo(id, data) {
  return request({
    url: `/qualities/${id}/reasons`,
    method: 'patch',
    data,
  })
}

/**
 * 根据id预览对应的详情图
 * @param {number} id
 */
export function getFileView(id) {
  return request({
    url: `/qualities/files/${id}`,
    method: 'get',
  })
}

/**
 * 根据文件id删除对应的质量文件
 * @param {number} id
 */
export function deleteFile(id) {
  return request({
    url: `/qualities/files/${id}`,
    method: 'delete',
  })
}

/**
 * 导出筛选记录、
 * @param {*} data 导出记录参数
 */
export function exportFilteredQualities(data) {
  return request({
    url: `/qualities/exports/filtered`,
    method: 'get',
    responseType: 'blob',
    params: data,
  })
}

/**
 * 获取处置单号
 * @param {number} id
 */
export function getQualitiesReceipt(receiptNumber) {
  return request({
    url: `/qualities/receipts/${receiptNumber}`,
    method: 'get',
  })
}

/**
 * 批量获取处置单号
 * @param {number} id
 */
export function getBatchQualitiesReceipt(ids) {
  return request({
    url: `/qualities/receipts`,
    method: 'get',
    params: ids,
  })
}

// 生成退库交接单
export function createReturnRecord(data) {
  return request({
    url: `/qualities/return-records`,
    method: 'put',
    data,
  })
}
// 获取退库交接单
export function getReturnCard(data) {
  return request({
    url: `/qualities/return-records/return-receipt`,
    method: 'get',
    params: data,
  })
}

/**
 * 撤销指定id的报废记录
 */
export function revertQuality(id, data) {
  return request({
    url: `/qualities/${id}/status`,
    method: 'delete',
    data,
  })
}
