var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "top-tip" }, [
        _c(
          "div",
          { staticClass: "selected-wrapper" },
          [
            _vm.hasSelected
              ? _c("span", [
                  _vm._v(" 已选中 "),
                  _c("strong", [_vm._v(_vm._s(_vm.selectedRowKeys.length))]),
                  _vm._v(" 项计划 "),
                ])
              : _vm._e(),
            _vm.hasSelected
              ? _c(
                  "a-dropdown",
                  [
                    _c(
                      "a",
                      {
                        staticClass: "batch-operate-link",
                        on: {
                          click: function (e) {
                            return e.preventDefault()
                          },
                        },
                      },
                      [
                        _c("a-icon", { attrs: { type: "edit" } }),
                        _vm._v("批量操作 "),
                      ],
                      1
                    ),
                    _c(
                      "a-menu",
                      { attrs: { slot: "overlay" }, slot: "overlay" },
                      [
                        _c("a-menu-item", [
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.handleCreateSaveInventoryCard(
                                    _vm.selectedRowKeys
                                  )
                                },
                              },
                            },
                            [_vm._v("生成出库单")]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm.selectVisible
        ? _c(
            "a-select",
            {
              staticClass: "type-select",
              on: { change: _vm.handleTypeChange },
              model: {
                value: _vm.currentActiveType,
                callback: function ($$v) {
                  _vm.currentActiveType = $$v
                },
                expression: "currentActiveType",
              },
            },
            [
              _c("a-select-option", { attrs: { value: "L" } }, [
                _vm._v("L计划"),
              ]),
              _c("a-select-option", { attrs: { value: "D" } }, [
                _vm._v("D计划"),
              ]),
              _c("a-select-option", { attrs: { value: "G" } }, [
                _vm._v("G计划"),
              ]),
              _c("a-select-option", { attrs: { value: "ALL" } }, [
                _vm._v("所有计划"),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c(
        "a-tabs",
        {
          attrs: { "active-key": _vm.currentActiveKey, type: "card" },
          on: { change: _vm.handleTabChange },
        },
        [
          _c("a-tab-pane", { key: "0", attrs: { tab: "成品库房" } }),
          _c("a-tab-pane", { key: "1", attrs: { tab: "半成品库房" } }),
          _c("a-tab-pane", { key: "2", attrs: { tab: "工装库" } }),
          _c("a-tab-pane", { key: "3", attrs: { tab: "材料库" } }),
          _c("a-tab-pane", { key: "4", attrs: { tab: "废品库" } }),
          _c("a-tab-pane", { key: "5", attrs: { tab: "来料退回库" } }),
          _c("a-tab-pane", { key: "6", attrs: { tab: "所有库" } }),
        ],
        1
      ),
      _c("a-table", {
        key: _vm.tableKey,
        attrs: {
          "row-key": "id",
          bordered: "",
          scroll: { x: 1700 },
          columns: _vm.inventoryColumns,
          "data-source": _vm.inventoryList,
          "row-selection": {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange,
            getCheckboxProps: _vm.inventoryRowSelection.getCheckboxProps,
          },
          pagination: _vm.pagination,
          loading: _vm.loading,
        },
        on: { change: _vm.handleTableChange },
        scopedSlots: _vm._u([
          {
            key: "filterDropdown",
            fn: function (ref) {
              var setSelectedKeys = ref.setSelectedKeys
              var selectedKeys = ref.selectedKeys
              var confirm = ref.confirm
              var clearFilters = ref.clearFilters
              var column = ref.column
              return _c(
                "div",
                { staticStyle: { padding: "8px" } },
                [
                  column.searchType === "text"
                    ? [
                        _c("a-input", {
                          directives: [
                            {
                              name: "ant-ref",
                              rawName: "v-ant-ref",
                              value: function (c) {
                                return (_vm.searchInput = c)
                              },
                              expression: "c => (searchInput = c)",
                            },
                          ],
                          staticStyle: {
                            width: "188px",
                            "margin-bottom": "8px",
                            display: "block",
                          },
                          attrs: {
                            placeholder: "搜索 " + column.title,
                            value: selectedKeys[0],
                          },
                          on: {
                            change: function (e) {
                              return setSelectedKeys(
                                e.target.value ? [e.target.value] : []
                              )
                            },
                            pressEnter: function () {
                              return _vm.handleSearch(
                                selectedKeys,
                                confirm,
                                column.key
                              )
                            },
                          },
                        }),
                      ]
                    : column.searchType === "number"
                    ? _c(
                        "div",
                        [
                          _c("a-input-number", {
                            staticStyle: {
                              width: "95px",
                              "margin-bottom": "8px",
                              display: "inline-block",
                            },
                            attrs: {
                              size: "small",
                              placeholder: "最小值",
                              min: 0,
                              max: 999999,
                            },
                            on: {
                              change: function (e) {
                                return setSelectedKeys([e, selectedKeys[1]])
                              },
                              pressEnter: function () {
                                return _vm.handleSearch(
                                  selectedKeys,
                                  confirm,
                                  column.key
                                )
                              },
                            },
                            model: {
                              value: selectedKeys[0],
                              callback: function ($$v) {
                                _vm.$set(selectedKeys, 0, $$v)
                              },
                              expression: "selectedKeys[0]",
                            },
                          }),
                          _c("span", [_vm._v("-")]),
                          _c("a-input-number", {
                            staticStyle: {
                              width: "95px",
                              "margin-bottom": "8px",
                              display: "inline-block",
                            },
                            attrs: {
                              size: "small",
                              placeholder: "最大值",
                              min: selectedKeys[0],
                              max: 999999,
                            },
                            on: {
                              change: function (e) {
                                return setSelectedKeys([selectedKeys[0], e])
                              },
                              pressEnter: function () {
                                return _vm.handleSearch(
                                  selectedKeys,
                                  confirm,
                                  column.key
                                )
                              },
                            },
                            model: {
                              value: selectedKeys[1],
                              callback: function ($$v) {
                                _vm.$set(selectedKeys, 1, $$v)
                              },
                              expression: "selectedKeys[1]",
                            },
                          }),
                        ],
                        1
                      )
                    : column.searchType === "date"
                    ? _c(
                        "div",
                        [
                          _c("a-range-picker", {
                            staticStyle: {
                              width: "190px",
                              "margin-bottom": "8px",
                            },
                            attrs: {
                              size: "small",
                              ranges: {
                                今天: [_vm.$moment(), _vm.$moment()],
                                本月: [
                                  _vm.$moment().startOf("month"),
                                  _vm.$moment().endOf("month"),
                                ],
                              },
                            },
                            on: {
                              change: function (dates, dateStrings) {
                                return setSelectedKeys([].concat(dateStrings))
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "a-button",
                    {
                      staticStyle: { width: "90px", "margin-right": "8px" },
                      attrs: { type: "primary", icon: "search", size: "small" },
                      on: {
                        click: function () {
                          return _vm.handleSearch(
                            selectedKeys,
                            confirm,
                            column.key
                          )
                        },
                      },
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "a-button",
                    {
                      staticStyle: { width: "90px" },
                      attrs: { size: "small" },
                      on: {
                        click: function () {
                          return _vm.handleReset(
                            selectedKeys,
                            clearFilters,
                            column.key
                          )
                        },
                      },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                2
              )
            },
          },
          {
            key: "isParts",
            fn: function (text) {
              return _c(
                "span",
                {},
                [
                  text === 0
                    ? _c("a-tag", { attrs: { color: "green" } }, [_vm._v("否")])
                    : _vm._e(),
                  text === 1
                    ? _c("a-tag", { attrs: { color: "red" } }, [_vm._v("是")])
                    : _vm._e(),
                ],
                1
              )
            },
          },
          {
            key: "status",
            fn: function (text) {
              return _c(
                "span",
                {},
                [
                  text === "NRM"
                    ? _c("a-tag", { attrs: { color: "green" } }, [
                        _vm._v("正常"),
                      ])
                    : _vm._e(),
                  text === "OCN"
                    ? _c("a-tag", { attrs: { color: "orange" } }, [
                        _vm._v("出库单撤销"),
                      ])
                    : _vm._e(),
                  text === "WOT"
                    ? _c("a-tag", { attrs: { color: "blue" } }, [
                        _vm._v("待出库"),
                      ])
                    : _vm._e(),
                  text === "FOT"
                    ? _c("a-tag", { attrs: { color: "red" } }, [
                        _vm._v("已出库"),
                      ])
                    : _vm._e(),
                ],
                1
              )
            },
          },
          {
            key: "warehouseType",
            fn: function (text) {
              return _c(
                "span",
                {},
                [
                  text === 1
                    ? _c("a-tag", { attrs: { color: "orange" } }, [
                        _vm._v("半成品"),
                      ])
                    : _vm._e(),
                  text === 0
                    ? _c("a-tag", { attrs: { color: "green" } }, [
                        _vm._v("成品"),
                      ])
                    : _vm._e(),
                  text === 2
                    ? _c("a-tag", { attrs: { color: "blue" } }, [
                        _vm._v("工装"),
                      ])
                    : _vm._e(),
                  text === 4
                    ? _c("a-tag", { attrs: { color: "red" } }, [_vm._v("废品")])
                    : _vm._e(),
                  text === 5
                    ? _c("a-tag", { attrs: { color: "red" } }, [
                        _vm._v("来料退回库"),
                      ])
                    : _vm._e(),
                  text === 3
                    ? _c("a-tag", { attrs: { color: "orange" } }, [
                        _vm._v("材料"),
                      ])
                    : _vm._e(),
                ],
                1
              )
            },
          },
          {
            key: "oddNumbers",
            fn: function (text, column) {
              return [
                column.takeOutNumber
                  ? [
                      _c(
                        "el-link",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.handleCheckInventory(column)
                            },
                          },
                        },
                        [_vm._v(_vm._s(text))]
                      ),
                    ]
                  : [
                      _c(
                        "el-link",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.handleCreateSingleCard(column)
                            },
                          },
                        },
                        [_vm._v("点击生成")]
                      ),
                    ],
              ]
            },
          },
          {
            key: "takeOutComment",
            fn: function (text, column) {
              return _c(
                "div",
                {},
                [
                  _vm.commentEditId === column.id
                    ? [
                        _c("a-input", {
                          staticStyle: {
                            width: "150px",
                            "margin-right": "10px",
                          },
                          attrs: { value: text },
                          on: {
                            blur: function (e) {
                              return _vm.handleUpdateComment(
                                e.target.value,
                                column
                              )
                            },
                          },
                        }),
                      ]
                    : _c("a-tooltip", { attrs: { title: "点击更新备注" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              cursor: "pointer",
                              width: "100%",
                              height: "30px",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleEditComment(column.id)
                              },
                            },
                          },
                          [_c("span", [_vm._v(_vm._s(text))])]
                        ),
                      ]),
                ],
                2
              )
            },
          },
          {
            key: "actualUseMaterialList",
            fn: function (text) {
              return _c(
                "div",
                {},
                [
                  _c(
                    "a-tooltip",
                    [
                      _c(
                        "template",
                        { slot: "title" },
                        _vm._l(text, function (item, index) {
                          return _c("span", { key: index }, [
                            _vm._v(
                              " " +
                                _vm._s(item.materialName) +
                                "(" +
                                _vm._s(item.materialCode) +
                                ") "
                            ),
                            index < text.length - 1
                              ? _c("span", [_vm._v(",")])
                              : _vm._e(),
                          ])
                        }),
                        0
                      ),
                      _c(
                        "div",
                        { staticClass: "table-ellis" },
                        _vm._l(text, function (item, index) {
                          return _c("span", { key: index }, [
                            _vm._v(
                              _vm._s(item.materialName) +
                                "(" +
                                _vm._s(item.materialCode) +
                                "),"
                            ),
                          ])
                        }),
                        0
                      ),
                    ],
                    2
                  ),
                ],
                1
              )
            },
          },
          {
            key: "filterIcon",
            fn: function (filtered) {
              return _c("a-icon", {
                style: { color: filtered ? "#108ee9" : undefined },
                attrs: { type: "search" },
              })
            },
          },
          {
            key: "operation",
            fn: function (text, column) {
              return _c(
                "div",
                { staticClass: "table-operation" },
                [
                  _c(
                    "a-dropdown",
                    [
                      _c(
                        "a-menu",
                        {
                          staticClass: "ant-dropdown-link",
                          attrs: { slot: "overlay" },
                          slot: "overlay",
                        },
                        [
                          _c(
                            "a-menu-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.handleBatchGoods(column)
                                },
                              },
                            },
                            [_vm._v("出库分批")]
                          ),
                          column.takeOutNumber && !column.invoiceNumber
                            ? _c(
                                "a-menu-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleRevertModal(column)
                                    },
                                  },
                                },
                                [_vm._v("撤销出库单")]
                              )
                            : _vm._e(),
                          _c(
                            "a-menu-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.handleCheckLog(column)
                                },
                              },
                            },
                            [_vm._v("查看操作日志")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a",
                        { staticClass: "operation-btn" },
                        [
                          _vm._v(" 操作 "),
                          _c("a-icon", { attrs: { type: "down" } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            },
          },
        ]),
      }),
      _c(
        "el-dropdown",
        {
          staticClass: "export-craft",
          on: { command: _vm.handleExportCommand },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                loading: _vm.exportLoading,
                size: "medium",
                type: "primary",
                icon: "el-icon-download",
              },
            },
            [_vm._v("导出出库记录")]
          ),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c("el-dropdown-item", { attrs: { command: "FILTERED" } }, [
                _vm._v("导出当前筛选记录"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("CraftEditModal", {
        attrs: {
          "modal-title": _vm.currentEditType,
          "craft-form": _vm.currentCraft,
          visible: _vm.visible,
        },
      }),
      _vm.routeModalVisible
        ? _c("ProcessRouteCard", {
            attrs: {
              "modal-title": _vm.currentPlanCode,
              record: _vm.currentProcessRoute,
            },
            on: {
              cancel: _vm.handleRouteModalCanceled,
              confirm: _vm.handleRouteModalConfirmed,
            },
          })
        : _vm._e(),
      _vm.ifShowCheckInventoryModal
        ? _c("CheckInventoryModal", {
            attrs: { type: "out", "inventory-data": _vm.checkInventoryColumn },
            on: {
              cancel: _vm.handleCheckInventoryCanceled,
              confirm: _vm.handleCheckInventoryConfirmed,
            },
          })
        : _vm._e(),
      _vm.ifShowInventoryRecordsModal
        ? _c("InventoryRecordsModal", {
            attrs: { type: "out", "records-data": _vm.recordIds },
            on: {
              cancel: _vm.handleInventoryRecordsModalCanceled,
              confirm: _vm.handleInventoryRecordsModalConfirmed,
            },
          })
        : _vm._e(),
      _c(
        "a-modal",
        {
          attrs: { title: "分批信息确认" },
          on: { ok: _vm.handlebatchConfirm, cancel: _vm.handlebatchCancel },
          model: {
            value: _vm.batchVisible,
            callback: function ($$v) {
              _vm.batchVisible = $$v
            },
            expression: "batchVisible",
          },
        },
        [
          _c(
            "a-form-model",
            {
              ref: "batchForm",
              attrs: {
                rules: _vm.batchRules,
                model: _vm.batchForm,
                "label-col": _vm.batchForm.labelCol,
                "wrapper-col": _vm.batchForm.wrapperCol,
              },
            },
            [
              _c(
                "a-form-model-item",
                { ref: "count", attrs: { label: "分批数量", prop: "count" } },
                [
                  _c("a-input", {
                    model: {
                      value: _vm.batchForm.count,
                      callback: function ($$v) {
                        _vm.$set(_vm.batchForm, "count", $$v)
                      },
                      expression: "batchForm.count",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.receiptModalVisible
        ? _c("InventoryReceiptModal", {
            attrs: { id: _vm.receiptIds, type: "out" },
            on: {
              cancel: _vm.handleReceiptModalCanceled,
              confirm: _vm.handleReceiptModalConfirmed,
            },
          })
        : _vm._e(),
      _vm.logModalVisible
        ? _c("LogModal", {
            attrs: { id: _vm.logId, type: "OPC" },
            on: { cancel: _vm.handleLogCanceled },
          })
        : _vm._e(),
      _vm.revertModalVisible
        ? _c("RevertModal", {
            attrs: { record: _vm.revertRecord },
            on: {
              cancel: _vm.revertModalCancel,
              confirm: _vm.revertModalConfirm,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }