import { downloadItem } from '@/utils/api.request'
import { getProductImportTemplate } from '@/api/order'
import { getOperationImportTemplate } from '@/api/operation'
import { getUserImportTemplate } from '@/api/user'
import {
  getPlansCraftImportTemplate,
  getProcessRouteImportTemplate,
} from '@/api/craft'
import { getAllImportTemplate } from '@/api/product'
import { getCardTemplateImportTemplate } from '@/api/template'
import { getMaterialImportTemplate } from '@/api/material'
import { getPartsCraftImportTemplate } from '@/api/craft'
import { getSettleAccountImportTemplate } from '@/api/settlements'
import { getInquiryListImportTemplate } from '@/api/inquirylist'

import { getTenantsImportTemplate } from '@/api/tenants'
import { getSuppliersImportTemplate } from '@/api/suppliers'

import { CONSTANTS } from '@/utils/constants'
export function downloadTemplate(templateType) {
  switch (templateType) {
    case 'inquiry-list':
      getInquiryListImportTemplate().then((res) => downloadInquiryTemplate(res))
      break
    case 'tenants':
      getTenantsImportTemplate().then((res) => downloadTenantsTemplate(res))
      break
    case 'suppliers':
      getSuppliersImportTemplate().then((res) => downloadSuppliersTemplate(res))
      break
    case 'product':
      getProductImportTemplate().then((res) => downloadProductTemplate(res))
      break
    case 'orderProduct':
      getAllImportTemplate().then((res) => downloadOrderProductTemplate(res))
      break
    case 'operation':
      getOperationImportTemplate().then((res) => downloadOperationTemplate(res))
      break
    case 'processRoute':
      getProcessRouteImportTemplate().then((res) =>
        downloadProcessRouteTemplate(res)
      )
      break
    case 'user':
      getUserImportTemplate().then((res) => downloadUserTemplate(res))
      break
    case 'cardTemplate':
      getCardTemplateImportTemplate().then((res) =>
        downloadCardTemplateTemplate(res)
      )
      break
    case 'material':
      getMaterialImportTemplate().then((res) => downloadMaterialTemplate(res))
      break
    case 'parts':
      getPartsCraftImportTemplate().then((res) =>
        downloadPartsCraftTemplate(res)
      )
      break
    case 'plans':
      getPlansCraftImportTemplate().then((res) =>
        downloadPlansCraftTemplate(res)
      )
      break
    case 'settleAccount':
      getSettleAccountImportTemplate().then((res) =>
        downloadSettleAccountTemplate(res)
      )
      break
  }
}

function downloadProductTemplate(blobData) {
  downloadItem(
    blobData,
    'application/vnd.ms-excel',
    CONSTANTS.PRODUCT_TEMPLATE_FILE_NAME + CONSTANTS.EXPORT_FILE_SUFFIX
  )
}

function downloadOrderProductTemplate(blobData) {
  downloadItem(
    blobData,
    'application/vnd.ms-excel',
    CONSTANTS.ORDER_PRODUCT_TEMPLATE_FILE_NAME + CONSTANTS.EXPORT_FILE_SUFFIX
  )
}

function downloadOperationTemplate(blobData) {
  downloadItem(
    blobData,
    'application/vnd.ms-excel',
    CONSTANTS.OPERATION_TEMPLATE_FILE_NAME + CONSTANTS.EXPORT_FILE_SUFFIX
  )
}

function downloadProcessRouteTemplate(blobData) {
  downloadItem(
    blobData,
    'application/vnd.ms-excel',
    CONSTANTS.PROCESS_ROUTE_TEMPLATE_FILE_NAME + CONSTANTS.EXPORT_FILE_SUFFIX
  )
}

function downloadUserTemplate(blobData) {
  downloadItem(
    blobData,
    'application/vnd.ms-excel',
    CONSTANTS.USER_TEMPLATE_FILE_NAME + CONSTANTS.EXPORT_FILE_SUFFIX
  )
}

function downloadCardTemplateTemplate(blobData) {
  downloadItem(
    blobData,
    'application/vnd.ms-excel',
    CONSTANTS.CARD_TEMPLATE_FILE_NAME + CONSTANTS.EXPORT_FILE_SUFFIX
  )
}

function downloadMaterialTemplate(blobData) {
  downloadItem(
    blobData,
    'application/vnd.ms-excel',
    CONSTANTS.MATERIAL_TEMPLATE_FILE_NAME + CONSTANTS.EXPORT_FILE_SUFFIX
  )
}

function downloadPartsCraftTemplate(blobData) {
  downloadItem(
    blobData,
    'application/vnd.ms-excel',
    CONSTANTS.PARTS_TEMPLATE_CARFT_FILE_NAME + CONSTANTS.EXPORT_FILE_SUFFIX
  )
}

function downloadPlansCraftTemplate(blobData) {
  downloadItem(
    blobData,
    'application/vnd.ms-excel',
    CONSTANTS.BATCH_PLANS_TEMPLATE_CARFT_FILE_NAME +
      CONSTANTS.EXPORT_FILE_SUFFIX
  )
}

function downloadSettleAccountTemplate(blobData) {
  downloadItem(
    blobData,
    'application/vnd.ms-excel',
    CONSTANTS.SETTLE_TEMPLATE_ACCOUNT_FILE_NAME + CONSTANTS.EXPORT_FILE_SUFFIX
  )
}

function downloadTenantsTemplate(blobData) {
  downloadItem(
    blobData,
    'application/vnd.ms-excel',
    CONSTANTS.TENANTS_TEMPLATE_FILE_NAME + CONSTANTS.EXPORT_FILE_SUFFIX
  )
}

function downloadSuppliersTemplate(blobData) {
  downloadItem(
    blobData,
    'application/vnd.ms-excel',
    CONSTANTS.SUPPLIERS_TEMPLATE_FILE_NAME + CONSTANTS.EXPORT_FILE_SUFFIX
  )
}

function downloadInquiryTemplate(blobData) {
  downloadItem(
    blobData,
    'application/vnd.ms-excel',
    CONSTANTS.INQUIRY_TEMPLATE_FILE_NAME + CONSTANTS.EXPORT_FILE_SUFFIX
  )
}
