export const taskColumns = [
  {
    title: '任务编码',
    dataIndex: 'code',
    ellipsis: false,
    key: 'task.code',
    fixed: 'left',
    align: 'center',
    searchType: 'text',
    sorter: true,
    width: 150,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'codeTag',
    },
  },
  {
    title: '产品名称',
    dataIndex: 'productName',
    key: 'product.productName',
    ellipsis: false,
    fixed: 'left',
    align: 'center',
    searchType: 'text',
    width: 150,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '任务状态',
    dataIndex: 'taskStatus',
    key: 'task.status',
    width: 150,
    align: 'center',
    scopedSlots: {
      customRender: 'status',
    },
    filters: [
      {
        text: '进行中',
        value: 2,
      },
      {
        text: '已完成',
        value: 3,
      },
      {
        text: '超期进行中',
        value: 4,
      },
      {
        text: '即将超期',
        value: 5,
      },
      {
        text: '已取消',
        value: 8,
      },
      {
        text: '已暂停',
        value: 9,
      },
      {
        text: '异常终止',
        value: 10,
      },
    ],
  },
  {
    title: '紧急类型',
    width: 150,
    dataIndex: 'emgType',
    key: 'emgType',
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      customRender: 'emgType',
    },
    filters: [
      {
        text: '普通',
        value: 'NML',
      },
      {
        text: '必完件',
        value: 'MFN',
      },
      {
        text: '紧急件',
        value: 'UGC',
      },
      {
        text: '插入急件',
        value: 'IUG',
      },
      {
        text: '转入急件',
        value: 'TUG',
      },
    ],
  },
  {
    title: '任务类型',
    width: 150,
    dataIndex: 'taskType',
    key: 'taskType',
    align: 'center',
    scopedSlots: {
      customRender: 'taskType',
    },
    filters: [
      {
        text: '普通任务',
        value: 0,
      },
      {
        text: '返修任务',
        value: 1,
      },
      {
        text: '生产补投任务',
        value: 2,
      },
      {
        text: '退库补投任务',
        value: 3,
      },
      {
        text: '分批任务',
        value: 4,
      },
      {
        text: '其他任务',
        value: 5,
      },
    ],
  },
  {
    title: '任务实时位置',
    dataIndex: 'latestOperationName',
    key: 'latestOperationName',
    width: 150,
    align: 'center',
    searchType: 'text',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '停留时间(小时)',
    dataIndex: 'stayTime',
    key: 'stayTime',
    width: 150,
    align: 'center',
    searchType: 'number',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '订单编码',
    dataIndex: 'orderCode',
    key: 'orderCode',
    ellipsis: false,
    width: 150,
    searchType: 'text',
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    sorter: true,
  },

  {
    title: '图号',
    dataIndex: 'drawingNum',
    key: 'product.drawingNum',
    ellipsis: false,
    align: 'center',
    searchType: 'text',
    width: 150,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    sorter: true,
  },
  {
    title: '单位',
    dataIndex: 'unit',
    key: 'unit',
    align: 'center',
    width: 70,
  },
  {
    title: '订单数量',
    dataIndex: 'amount',
    key: 'product.amount',
    align: 'center',
    width: 140,
    searchType: 'number',
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '在产数量',
    dataIndex: 'producingNum',
    key: 'product.producingNum',
    align: 'center',
    width: 140,
    searchType: 'number',
    sorter: true,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '是否分件计划',
    dataIndex: 'isParts',
    key: 'plan.isParts',
    width: 150,
    align: 'center',
    scopedSlots: {
      customRender: 'isParts',
    },
    filters: [
      {
        text: '是',
        value: 1,
      },
      {
        text: '否',
        value: 0,
      },
    ],
  },
  {
    title: '计划下达日期',
    dataIndex: 'commandTime',
    key: 'plan.commandTime',
    align: 'center',
    ellipsis: true,
    width: 150,
    sorter: true,
    searchType: 'date',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '产品交货日期',
    dataIndex: 'deliverTime',
    key: 'plan.deliverTime',
    align: 'center',
    ellipsis: true,
    width: 150,
    sorter: true,
    searchType: 'date',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
  },
  {
    title: '客户名称',
    dataIndex: 'client',
    key: 'client',
    ellipsis: false,
    width: 150,
    searchType: 'text',
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    sorter: true,
  },
  {
    title: '工艺图文件',
    dataIndex: 'fileName',
    key: 'fileName',
    width: 150,
    ellipsis: false,
    align: 'center',
    scopedSlots: {
      customRender: 'fileName',
    },
  },
  {
    title: '标准工艺路线',
    key: 'standardProcess',
    align: 'center',
    width: 150,
    scopedSlots: {
      customRender: 'standardProcess',
    },
  },
  {
    title: '实时加工进程',
    key: 'process',
    width: 10000,
    scopedSlots: {
      customRender: 'process',
    },
  },
]
