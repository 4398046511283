<template>
  <div class="app-container">
    <div class="tip-box">
      <div class="null-tip" style="font-size: 15px">
        当前类型工艺剩余未编制数量：
        <span style="color: red">{{ notMakeCount }}</span> 项
      </div>
      <div v-if="hasSelected" class="top-tip">
        <div class="selected-wrapper">
          <span v-if="hasSelected">
            已选中
            <strong>{{ selectedRowKeys.length }}</strong> 项计划
          </span>
          <a-dropdown v-if="hasSelected">
            <a class="batch-operate-link" @click="(e) => e.preventDefault()">
              <a-icon type="edit" />批量操作
            </a>

            <a-menu slot="overlay">
              <a-menu-item>
                <a @click="handlePrintCraftCard(selectedRowKeys)">批量打印卡片</a>
              </a-menu-item>
              <a-menu-item v-if="batchExportTemplate">
                <a @click="handleBatchExportCraft(selectedRowKeys)">批量导入工艺模板</a>
              </a-menu-item>
              <a-menu-item v-if="batchCopyPartsCraft">
                <a @click="handleBatchCopyPartsCraft(selectedRowKeys)">批量复制分件卡片</a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      </div>
      <div class="download">
        <a-space>
          <c-upload name="file" accept=".xlsx" :show-upload-list="false"
            :action="`${uploadUrl}/crafts/operation-cards/imports`" @change="handleBatchFileChange">
            <a-button type="primary" :loading="uploading" slot="content">批量导入计划工艺</a-button>
          </c-upload>
          <a-popover placement="bottomRight">
            <template #content>
              <a-space direction="vertical">
                <el-button type="text" class="downloadBtn" icon="el-icon-download"
                  @click="handleDownloadTemplate('parts')">点击下载分件工艺导入模板</el-button>
                <el-button type="text" icon="el-icon-download"
                  @click="handleDownloadTemplate('processRoute')">点击下载工艺路线导入模板</el-button>
                <el-button type="text" icon="el-icon-download"
                  @click="handleDownloadTemplate('plans')">点击下载批量导入计划工艺模板</el-button>
              </a-space>
            </template>
            <a-button>下载导入模板</a-button>
          </a-popover>
        </a-space>
      </div>
    </div>
    <div class="top-dowload-btn">
      <div class="refresh">
        <a-tooltip>
          <template #title>点击清空筛选条件</template>
          <a-button @click="handleResetAll">刷新</a-button>
        </a-tooltip>
      </div>
    </div>
    <a-tabs :active-key="currentActiveKey" type="card" @change="handleTabChange">
      <a-tab-pane key="L" tab="L计划" />
      <a-tab-pane key="D" tab="D计划" />
      <a-tab-pane key="G" tab="G计划" />
    </a-tabs>
    <a-table :key="tableKey" row-key="id" :row-selection="{
      selectedRowKeys: selectedRowKeys,
      onChange: onSelectChange,
      onSelect: onSelectRows,
      onSelectAll: onSelectAllChange,
      getCheckboxProps: craftRowSelection.getCheckboxProps,
    }" bordered :scroll="{ x: 4000 }" :columns="craftColumns" :data-source="craftList" :pagination="pagination"
      :loading="loading" :row-class-name="(record) => (record.craftCode ? 'expand' : 'no-expand')"
      :expanded-row-keys="expandedRowKeys" @change="handleTableChange" @expand="handleRowExpand">
      <div slot="craftCode" slot-scope="text, column">
        <template v-if="text">
          <el-link type="success" @click="handleShowProcessRoute(column)">
            {{ text }}
          </el-link>
        </template>
        <template v-else-if="column.craftStatus === 8">
          <a-tag color="gray">已取消</a-tag>
        </template>
        <template v-else-if="column.craftStatus === 9">
          <a-tag color="pink">已暂停</a-tag>
        </template>
        <template v-else>
          <el-link type="danger" @click="handleAddPartCraftCard(column, column.isParts, false)">点击新增卡片</el-link>
        </template>
      </div>
      <div slot="fileName" slot-scope="text, column" class="file-wrapper">
        <template v-if="column.fileName">
          <div class="filename-wrapper">
            <span @click="handleDownloadFile(column.id, column.fileName)" class="file-link">{{ column.fileName }}</span>
          </div>
          <a-popconfirm title="您确定要删除此工艺图文件？" ok-text="确定" cancel-text="取消" @confirm="handleDeleteFile(column.id)">
            <a class="filename-delete">
              <a-icon type="delete" />
            </a>
          </a-popconfirm>
        </template>
        <template v-else>
          <c-upload name="file" accept=".pdf" :show-upload-list="false" :action="`${baseURL}crafts/${column.id}/file`"
            @change="handleCraftFileChange">
            <a class="file-link" slot="content">
              <a-icon type="upload" />&nbsp;上传工艺图文件
            </a>
          </c-upload>
        </template>
      </div>
      <div slot="isParts" slot-scope="text">
        <template v-if="text === 0">
          <a-tag color="red">否</a-tag>
        </template>
        <template v-if="text === 1">
          <a-tag color="green">是</a-tag>
        </template>
      </div>
      <div slot="processRoute" slot-scope="text, column" class="file-wrapper">
        <template v-if="column.whetherProcessRouteExists">
          <div class="filename-wrapper">
            <a-button type="link" @click="handleShowProcessRoute(column)">查看工艺路线</a-button>
          </div>
          <a-popconfirm title="您确定要删除此工艺路线？" ok-text="确定" cancel-text="取消"
            @confirm="handleDeleteProcessRoute(column.id)">
            <a class="filename-delete">
              <a-icon type="delete" />
            </a>
          </a-popconfirm>
        </template>
        <template v-else>
          <c-upload name="file" accept=".xlsx" :multiple="true" :show-upload-list="false"
            :action="`${baseURL}crafts/${column.id}/process-route/imports`" @change="handleProcessFileChange">
            <a class="file-link" slot="content">
              <a-icon type="upload" />&nbsp;导入工艺路线
            </a>
          </c-upload>
        </template>
      </div>
      <div slot="filterDropdown" slot-scope="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }" style="padding: 8px">
        <template v-if="column.searchType === 'text'">
          <a-input v-ant-ref="(c) => (searchInput = c)" :placeholder="`搜索 ${column.title}`" :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block" @change="(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
              " @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)" />
        </template>
        <template v-if="column.searchType === 'text-filter'">
          <a-input v-model="selectedKeys[0]" :placeholder="`搜索 ${column.title}`"
            style="width: 125px; margin-bottom: 8px; display: inline-block"
            @change="(e) => setSelectedKeys([e.target.value, selectedKeys[1]])" @pressEnter="() => handleSearch(selectedKeys, confirm, column.key, column)
              " />
          <span>-</span>
          <a-input v-model="selectedKeys[1]" :placeholder="`搜索 ${column.title}`"
            style="width: 125px; margin-bottom: 8px; display: inline-block"
            @change="(e) => setSelectedKeys([selectedKeys[0], e.target.value])" @pressEnter="() => handleSearch(selectedKeys, confirm, column.key, column)
              " />
        </template>
        <div v-else-if="column.searchType === 'number'">
          <a-input-number v-model="selectedKeys[0]" size="small" placeholder="最小值" :min="0" :max="999999"
            style="width: 95px; margin-bottom: 8px; display: inline-block"
            @change="(e) => setSelectedKeys([e, selectedKeys[1]])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)" />
          <span>-</span>
          <a-input-number v-model="selectedKeys[1]" size="small" placeholder="最大值" :min="selectedKeys[0]" :max="999999"
            style="width: 95px; margin-bottom: 8px; display: inline-block"
            @change="(e) => setSelectedKeys([selectedKeys[0], e])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)" />
        </div>
        <div v-else-if="column.searchType === 'date'">
          <a-range-picker size="small" style="width: 190px; margin-bottom: 8px" :ranges="{
            今天: [$moment(), $moment()],
            本月: [$moment().startOf('month'), $moment().endOf('month')],
          }" @change="(dates, dateStrings) => setSelectedKeys([...dateStrings])" />
        </div>
        <div>
          <a-button type="primary" icon="search" size="small" style="width: 90px; margin-right: 8px" @click="() => handleSearch(selectedKeys, confirm, column.key, column)
            ">搜索</a-button>
          <a-button size="small" style="width: 90px" @click="() => handleReset(selectedKeys, clearFilters, column.key, column)
            ">重置</a-button>
        </div>
      </div>
      <a-icon slot="filterIcon" slot-scope="filtered" type="search"
        :style="{ color: filtered ? '#108ee9' : undefined }" />
      <div slot="operation" slot-scope="text, column" class="operation">
        <a-dropdown v-if="column.craftStatus !== 8 &&
          column.craftStatus !== 9 &&
          (column.craftCode || copyCardId || copyFatherCardId)
        ">
          <a class="operation-btn" @click="(e) => e.preventDefault()">
            操作&nbsp;
            <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item v-if="column.craftCode" @click="handleUpdateCraft(column)">
              <span>更新工艺</span>
            </a-menu-item>
            <a-menu-item v-if="column.craftCode && column.isParts === 0"
              @click="handleAddPartCraftCard(column, true)">新增分件卡片</a-menu-item>
            <a-menu-item v-if="column.craftCode">
              <a-popconfirm ok-text="删除工艺" cancel-text="取消" @confirm="handleDeleteCraft(column)">
                <template slot="title">
                  <p>{{ '确认删除工艺[' + column.craftCode + ']?' }}</p>
                </template>
                <a href="#">删除工艺</a>
              </a-popconfirm>
            </a-menu-item>
            <a-menu-item v-if="column.craftCode && column.isParts === 0"
              @click="handleCopyIntactCard(column)">复制完整工艺</a-menu-item>
            <a-menu-item v-if="!column.craftCode && column.isParts === 0 && copyFatherCardId
            ">
              <a-popconfirm ok-text="粘贴" cancel-text="取消" @confirm="handlePasteIntactCard(column)">
                <template slot="title">
                  <p>{{ '该操作无法撤销，确认粘贴完整工艺?' }}</p>
                </template>
                <a href="#">粘贴完整工艺</a>
              </a-popconfirm>
            </a-menu-item>
            <a-menu-item v-if="copyCardId &&
              copyCardPartsType === 0 &&
              !column.craftCode &&
              column.isParts === 0
            " @click="handlePasteCraftCard(column, 0, 0)">粘贴为主件卡片</a-menu-item>
            <a-menu-item v-if="copyCardId &&
              copyCardPartsType === 1 &&
              !column.craftCode &&
              column.isParts === 1
            " @click="handlePasteCraftCard(column, 1, 0)">粘贴为分件卡片</a-menu-item>
            <a-menu-item v-if="copyCardId && column.craftCode && column.isParts === 0"
              @click="handlePasteCraftCard(column, 1, 1)">插入粘贴分件卡片</a-menu-item>
            <a-menu-item v-if="batchCopyIds.length && column.craftCode && column.isParts === 0
            " @click="handleBatchPasteCraftCard(column)">批量插入粘贴分件卡片</a-menu-item>
            <a-menu-item v-if="column.craftCode && column.isParts === 0">
              <c-upload name="file" accept=".xlsx" :multiple="true" :show-upload-list="false"
                :action="`${baseURL}/crafts/${column.id}/parts/imports`" @change="handleFileChange">
                <span slot="content">批量导入分件工艺</span>
              </c-upload>
            </a-menu-item>
            <a-menu-item v-if="column.craftCode" @click="handleAddTaskCard(column)">
              <span>新增任务卡片</span>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
      <div slot="craftStatus" slot-scope="text">
        <template v-if="text === 2">
          <a-badge status="processing" />进行中
        </template>
        <template v-if="text === 3">
          <a-badge status="success" />已完成
        </template>
        <template v-if="text === 4">
          <a-badge status="error" />超期进行中
        </template>
        <template v-if="text === 5">
          即将超期
          <a-badge status="warning" />
        </template>
        <template v-if="text === 9">
          <a-badge status="warning" />暂停中
        </template>
        <template v-if="text === 8">
          <a-badge status="error" />已取消
        </template>
        <template v-if="text === 10">
          <a-badge status="error" />异常终止
        </template>
      </div>
      <a-table :key="tableKey" slot="expandedRowRender" row-key="id" size="middle" :loading="craftCardLoading"
        :columns="craftCardColumns" :data-source="craftCardData" :pagination="false" :customRow="handleCustomRow">
        <template slot="operationCardVersion" slot-scope="text, column">
          <el-link type="primary" @click="handleCheckCraftCard(column)">
            {{ text }}
          </el-link>
        </template>
        <div slot="processRoute" slot-scope="text, column" class="file-wrapper">
          <template v-if="column.processRoute && column.processRoute.length">
            <div class="filename-wrapper">
              <a-button type="link" @click="handleShowSingleProcessRoute(column)">查看工艺路线</a-button>
            </div>
          </template>
        </div>
        <div slot="status" slot-scope="text, column">
          <template v-if="column.operationCardFooterDTO.craftApproveDTO.approverId">审批完成</template>
          <template v-else-if="column.operationCardFooterDTO.craftDesignDTO.designerId">
            <a-button type="link" @click="handleApproveCard(column)">点击审批</a-button>
          </template>
        </div>
        <div slot="operation" slot-scope="text, column" class="operation">
          <a-dropdown>
            <a class="operation-btn" @click="(e) => e.preventDefault()">
              操作&nbsp;
              <a-icon type="down" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item v-if="column.operationCardHeaderDTO.operationCardBaseDTO
                .operationCardType === 0
              " @click="handleCopyAddCraftCard(column)">
                <span>新增版本</span>
              </a-menu-item>
              <a-menu-item @click="handleCreateTaskCraftCard(column)">新增实际工艺版本</a-menu-item>
              <a-menu-item v-if="!column.operationCardFooterDTO.craftApproveDTO.approverId"
                @click="handleUpdateCraftCard(column)">更新</a-menu-item>
              <a-menu-item v-if="!column.operationCardFooterDTO.craftApproveDTO.approverId"
                @click="handleDeleteCraftCard(column)">删除</a-menu-item>
              <a-menu-item v-if="column.operationCardHeaderDTO.operationCardBaseDTO
                .operationCardType === 0
              " @click="handleCopyCraftCard(column)">复制卡片</a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      </a-table>
    </a-table>
    <el-dropdown class="export-craft" @command="handleExportCraftCommand">
      <el-button :loading="exportLoading" size="medium" type="primary" icon="el-icon-download">导出工艺</el-button>
      <el-dropdown-menu slot="dropdown">
        <!-- <el-dropdown-item command="SELECTED">导出当前已选工艺</el-dropdown-item>
        <el-dropdown-item command="PAGE">导出当前页工艺</el-dropdown-item>-->
        <el-dropdown-item command="FILTERED">导出当前筛选工艺</el-dropdown-item>
        <!-- <el-dropdown-item command="ALL">导出所有工艺</el-dropdown-item> -->
      </el-dropdown-menu>
    </el-dropdown>
    <CraftEditModal :modal-title="currentEditType" :craft-form="currentCraft" :visible="visible"
      @cancle="handleEditCancel" />
    <ProcessRouteCard v-if="routeModalVisible" :modal-title="currentPlanCode"
      :select-visible="processRouteSelectVisiable" :record="currentProcessRoute" :current-version="currentVersion"
      :craft-data="craftCardData" @cancel="handleRouteModalCanceled" @confirm="handleRouteModalConfirmed" />
    <ApproveCardModal v-if="approveCardModalVisible" :card-id="approveId" @cancel="handleApproveModalCanceled"
      @confirm="handleApproveModalConfirmed" />
    <TaskCardChooseModal v-if="taskCardChooseModalVisible" :plan-id="taskCardPlanId" @cancel="handleTaskModalCanceled"
      @confirm="handleTaskModalConfirmed" />
    <TaskCraftChooseModal v-if="taskCraftChooseModalVisible"
      :plan-id="actualColumn.operationCardHeaderDTO.craftPlanDTO.planId" @cancel="handleCraftTaskModalCanceled"
      @confirm="handleCraftTaskModalConfirmed" />
  </div>
</template>

<script>
import { deepClone } from '@/utils'
import { downloadItem, handleDownLoad } from '@/utils/api.request'
import { CONSTANTS } from '@/utils/constants'
import { craftColumns, craftCardColumns, craftRowSelection } from './dataLogic'
import { message } from 'ant-design-vue'
import { uploadFile } from '@/common'
import ApproveCardModal from '@/components/ApproveCardModal'
import CraftEditModal from '@/components/CraftEditModal'
import ProcessRouteCard from '@/components/ProcessRouteCard'
import TaskCardChooseModal from '@/components/TaskCardChooseModal'
import {
  pasteCraftOperation,
  getNotMakeCount,
  getCrafts,
  deleteCraft,
  deleteCraftFile,
  exportCrafts,
  deleteCraftProcessRoute,
  getCraftCardVersionMessage,
  deleteCraftCardMessageById,
  batchPastePartsCraft,
  getRepairTasks,
  getTaskProcessRoute,
} from '@/api/craft'
import { downloadTemplate } from '@/common/templateDownload'
import TaskCraftChooseModal from '@/components/TaskCraftChooseModal'
// import Upload from '@/components/Upload'

export default {
  components: {
    CraftEditModal,
    ProcessRouteCard,
    ApproveCardModal,
    TaskCardChooseModal,
    TaskCraftChooseModal,
    // Upload,
  },
  data() {
    return {
      tableKey: 0,
      loading: false,
      craftList: [],
      craftColumns,
      craftCardColumns,
      craftRowSelection,
      currentCraftId: null,
      expandedRowKeys: [],
      selectedRowKeys: [],
      selectedRowPlanIds: [],
      searchInput: null,
      baseURL: '',
      fileURL: '',
      exportIds: [],
      pagination: {
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '20', '30', '40', '50'],
        showTotal: (total) => `共有 ${total} 条数据`,
        showQuickJumper: true,
      },
      currentCraftListQueryParams: {
        pageNum: 1,
        pageSize: 10,
        sort_by: null,
        'product.type': 'eq:L',
      },
      currentActiveKey: 'L',
      currentEditType: '更新',
      currentCraft: {},
      currentPlanCode: '',
      currentProcessRoute: [],
      visible: false,
      routeModalVisible: false,
      approveCardModalVisible: false,
      approveId: '',

      columnSearchParams: {},
      craftCardData: [],
      currentVersion: '',
      processRouteSelectVisiable: true,
      craftCardLoading: false,
      copyCardId: '',
      copyCardPartsType: '',
      copyFatherCardId: '',
      notMakeCount: 0,

      batchExportTemplate: true,
      batchExportTemplateMessage: {},

      batchExportPartsCraft: true,

      batchCopyPartsCraft: true,
      batchCopyIds: [],

      taskCardChooseModalVisible: false,
      taskCardPlanId: '',
      taskCardCraftId: '',
      taskCardColumn: '',
      hasSelectedRows: [],
      exportLoading: false,

      taskCraftChooseModalVisible: false,
      actualColumn: '',
      uploading: false,
      uploadUrl: process.env.VUE_APP_BASE_API,
    }
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0
    },
  },
  created() {
    // 初始化加载数据
    this.baseURL = process.env.VUE_APP_BASE_API
    this.getCrafts(this.currentCraftListQueryParams)
    this.getNotMakeCount()
  },
  methods: {
    handleTabChange(activeKey) {
      this.currentCraftListQueryParams['product.type'] = 'eq:' + activeKey
      this.currentCraftListQueryParams.pageNum = 1
      this.columnSearchParams = {}
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.getCrafts(this.currentCraftListQueryParams)
      this.currentActiveKey = activeKey
      this.tableKey += 1
      this.getNotMakeCount()
    },
    getNotMakeCount() {
      getNotMakeCount({ productType: this.currentActiveKey }).then((res) => {
        this.notMakeCount = res.data
      })
    },
    // 获取工艺数据
    getCrafts(data) {
      this.loading = true
      getCrafts(data)
        .then((res) => {
          this.craftList = res.data.records
          this.pagination.total = res.data.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 根据当前分页数据和搜索条件查询数据
    getCraftsByParams() {
      const cQueryParams = deepClone(this.currentCraftListQueryParams)
      const queryParams = Object.assign(cQueryParams, this.columnSearchParams)
      this.getCrafts(queryParams)
    },
    handleSearch(selectedKeys, confirm, dataIndex, column) {
      confirm()
      this.columnSearchParams[dataIndex] = ''
      if (selectedKeys && selectedKeys.length === 1) {
        if (selectedKeys[0].trim() !== '') {
          this.columnSearchParams[dataIndex] = 'like:' + selectedKeys[0].trim()
        }
      }
      if (selectedKeys && selectedKeys.length === 2) {
        if (column.searchType === 'text-filter') {
          if (selectedKeys[0] && !selectedKeys[1]) {
            selectedKeys[1] = selectedKeys[0]
          } else if (selectedKeys[1] && !selectedKeys[0]) {
            selectedKeys[0] = selectedKeys[1]
          }
        }
        if (selectedKeys[0] || selectedKeys[1]) {
          this.columnSearchParams[dataIndex] = 'btn:' + selectedKeys.toString()
        }
      }
      console.log(column, selectedKeys)
    },
    // 重置搜索筛选条件
    handleReset(selectedKeys, clearFilters, dataIndex, column) {
      // clearFilters()
      console.log('column', column, clearFilters)
      this.columnSearchParams[dataIndex] = null
    },
    handleResetAll() {
      this.columnSearchParams = {}
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.currentCraftListQueryParams.pageNum = 1
      this.currentCraftListQueryParams.sort_by = null
      this.tableKey += 1
      this.getCraftsByParams()
    },
    onSelectRows(record, selected, selectedRows) {
      if (selected) {
        this.hasSelectedRows.push(record)
      } else {
        const delIndex = this.hasSelectedRows.findIndex((val) => {
          return val.id === record.id
        })
        this.hasSelectedRows.splice(delIndex, 1)
      }
      console.log('选择', record, selected, selectedRows, this.hasSelectedRows)
      this.handleBatchOptions()
    },
    onSelectAllChange(selected, selectedRows, changeRows) {
      if (selected) {
        this.hasSelectedRows = this.hasSelectedRows.concat(changeRows)
      } else {
        let selectedRows = JSON.parse(JSON.stringify(this.hasSelectedRows))
        const delIndex = []
        selectedRows.forEach((item, index) => {
          changeRows.forEach((val, itemIndex) => {
            if (item.id === val.id) {
              delIndex.push(index)
            }
          })
        })
        delIndex.forEach((item) => {
          delete selectedRows[item]
        })
        selectedRows = selectedRows.filter((item) => {
          return item !== undefined
        })
        this.hasSelectedRows = selectedRows
      }
      this.handleBatchOptions()
      console.log('选择全部', this.hasSelectedRows)
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleBatchOptions() {
      this.batchExportTemplate = true
      this.batchCopyPartsCraft = true
      // 判断是否处于批量导入工艺模板的情况
      this.hasSelectedRows.map((item) => {
        if (item.craftCode) {
          this.batchExportTemplate = false
        }
      })
      if (this.batchExportTemplate) {
        this.batchExportTemplateMessage = this.hasSelectedRows[0]
        this.batchExportPartsCraft = false
        this.batchCopyPartsCraft = false
        return
      }

      // 判断是否处于 分件批量复制粘贴的情况
      this.hasSelectedRows.map((item) => {
        if (!item.craftCode) {
          this.batchCopyPartsCraft = false
        }
        if (item.isParts === 0) {
          this.batchCopyPartsCraft = false
        }
      })
      if (this.batchCopyPartsCraft) {
        this.batchExportTemplate = false
        this.batchExportPartsCraft = false
        return
      }
    },

    handleTableChange(pagination, filters, sorter) {
      const filtersKeys = Object.keys(filters)
      if (filtersKeys.includes('isParts')) {
        delete this.columnSearchParams['isParts']
        if (filters['isParts'].length > 0) {
          this.columnSearchParams['isParts'] = `in:${filters[
            'isParts'
          ].toString()}`
        } else {
          delete this.columnSearchParams['isParts']
        }
      }
      if (filtersKeys.includes('craftStatus')) {
        delete this.columnSearchParams['craftStatus']
        if (filters['craftStatus'].length > 0) {
          this.columnSearchParams['craftStatus'] = `in:${filters[
            'craftStatus'
          ].toString()}`
        } else {
          delete this.columnSearchParams['craftStatus']
        }
      }
      this.pagination = pagination
      this.currentCraftListQueryParams.pageNum = pagination.current
      this.currentCraftListQueryParams.pageSize = pagination.pageSize

      if (sorter.order) {
        const sortType = sorter.order === 'ascend' ? '+' : '-'
        this.currentCraftListQueryParams.sort_by = sortType + sorter.columnKey
      } else {
        this.currentCraftListQueryParams.sort_by = null
      }

      this.getCraftsByParams()
    },
    // 删除工艺
    handleDeleteCraft(column) {
      this.$confirm({
        content: '确认删除工艺?',
        onOk: () => {
          deleteCraft(column.id).then((res) => {
            if (res) {
              this.$message.success('删除工艺成功！')
              this.getCraftsByParams()
              this.getNotMakeCount()
            }
          })
        },
      })
    },
    // 更新工艺
    handleUpdateCraft(column) {
      this.visible = true
      this.currentCraft = deepClone(column)
    },
    async handleShowProcessRoute(column) {
      await this.getCraftCardData(column.id)
      this.currentProcessRoute = this.craftCardData[0].processRoute
      this.currentVersion =
        this.craftCardData[0].operationCardHeaderDTO.operationCardBaseDTO.operationCardVersion
      this.processRouteSelectVisiable = true
      this.routeModalVisible = true
    },
    handleShowSingleProcessRoute(column) {
      this.processRouteSelectVisiable = false
      this.currentVersion = column.operationCardVersion
      this.currentProcessRoute = column.processRoute
      this.routeModalVisible = true
    },
    // 处理文件上传
    handleCraftFileChange(info) {
      this.uploading = true
      if (info.file.status === 'done') {
        if (info.file.response) {
          this.getCraftsByParams()
        }
      } else if (info.file.status === 'error') {
        this.uploading = false
        this.$message.error(`文件导入失败，请稍后再试`)
      }
    },
    // 删除文件
    handleDeleteFile(id) {
      this.loading = true
      deleteCraftFile(id)
        .then(() => {
          message.success('删除工艺图文件成功！')
          this.getCraftsByParams()
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleRouteModalConfirmed() {
      this.routeModalVisible = false
    },
    handleRouteModalCanceled() {
      this.routeModalVisible = false
    },
    handleEditCancel() {
      this.visible = false
    },
    getExportIdsFromSelection() {
      this.exportIds = this.selectedRowKeys
    },
    getExportIdsFromPage() {
      this.exportIds = this.craftList.map((item) => {
        return item.id
      })
    },
    handleExportCraftCommand(command) {
      this.exportLoading = true
      switch (command) {
        case 'FILTERED':
          exportCrafts({
            'product.type': 'eq:' + this.currentActiveKey,
            ...this.columnSearchParams,
          })
            .then((res) => {
              this.downloadCraft(res)
            })
            .finally(() => {
              this.exportLoading = false
            })
          break
        default:
          break
      }
    },
    downloadCraft(blobData) {
      const currentDate = +Date.now()
      downloadItem(
        blobData,
        'application/vnd.ms-excel',
        CONSTANTS.CRAFT_FILE_NAME + currentDate + CONSTANTS.EXPORT_FILE_SUFFIX
      )
    },
    handleDownloadTemplate(name) {
      downloadTemplate(name)
    },

    handleProcessFileChange(info) {
      this.loading = true
      if (info.file.status === 'done') {
        if (info.file.response) {
          this.showImportResults(info.file.response)
        }
      } else if (info.file.status === 'error') {
        this.loading = false
        this.$message.error(`导入数据异常，请确认后重试`)
        this.getCraftCardData(this.expandedRowKeys[0])
      }
    },
    showImportResults(response) {
      const h = this.$createElement
      const that = this
      this.$info({
        title: '工艺路线信息导入结果',
        width: 600,
        content: h('div', {}, [
          h('p', '导入路线数据总计：' + response.totalNum + '条;'),
          h('p', '非法路线数据条数：' + response.failedNum + ';'),
          h('p', '导入成功路线数据条数：' + response.successfulNum + ';'),
          h('p', '非法路线数据信息:'),
          h('p', response.invalidMessages.join(' ||  ')),
        ]),
        onOk() {
          that.$destroyAll()
          that.loading = false
          that.getCraftCardData(that.expandedRowKeys[0])
        },
      })
    },
    handleDeleteProcessRoute(craftId) {
      deleteCraftProcessRoute(craftId).then((res) => {
        if (res) {
          message.success('删除工艺路线成功！')
          this.getCraftCardData(this.expandedRowKeys[0])
        }
      })
    },
    handleAddPartCraftCard(column, isParts, withMainCard = true) {
      let routeData
      if (withMainCard || !isParts) {
        routeData = this.$router.resolve({
          // name: 'craft-card-manage',
          name: 'operation-card',
          query: {
            ids: column.id,
            type: 'add',
            isParts: isParts ? 1 : 0,
          },
        })
      } else {
        routeData = this.$router.resolve({
          // name: 'craft-card-manage',
          name: 'operation-card',
          query: {
            ids: column.id,
            type: 'addPartsCard',
          },
        })
      }
      window.open(routeData.href, '_blank')
      this.handleModalTips()
    },
    handleDeleteCraft(column) {
      deleteCraft(column.id).then((res) => {
        if (res.status === 204) {
          this.$message.success('删除成功')
          this.getCraftsByParams()
          this.getNotMakeCount()
        }
      })
    },
    handleBatchAddCraftCard() {
      const routeData = this.$router.resolve({
        name: 'craft-card-manage',
        query: {
          ids: this.selectedRowKeys.join(','),
          type: 'add',
        },
      })
      window.open(routeData.href, '_blank')
      this.handleModalTips()
    },
    handleRowExpand(expanded, column) {
      this.currentCraftId = null
      if (expanded) {
        this.currentCraftId = column.id
        this.expandedRowKeys = []
        this.expandedRowKeys.push(column.id)
        this.getCraftCardData(column.id)
      } else {
        this.expandedRowKeys = []
      }
    },
    getCraftCardData(id) {
      this.craftCardLoading = true
      return new Promise((resolve, reject) => {
        getCraftCardVersionMessage(id).then((res) => {
          this.craftCardData = res.data
          this.craftCardData.map((card) => {
            const processRoute = []
            if (card.operationCardBodyDTO.craftProcessRouteListDTO.length) {
              card.operationCardBodyDTO.craftProcessRouteListDTO.map((val) => {
                val.processRoute.map((route) => {
                  processRoute.push(route)
                })
              })
            }
            card.processRoute = processRoute
          })
          this.craftCardLoading = false
          resolve()
        })
      })
    },
    handleCopyAddCraftCard(column) {
      const routeData = this.$router.resolve({
        // name: 'craft-card-manage',
        name: 'operation-card',
        query: {
          ids: column.operationCardHeaderDTO.operationCardBaseDTO.id,
          type: 'copyAdd',
        },
      })
      window.open(routeData.href, '_blank')
      this.handleModalTips()
    },
    handleUpdateCraftCard(column) {
      const routeData = this.$router.resolve({
        // name: 'craft-card-manage',
        name: 'operation-card',
        query: {
          ids: column.operationCardHeaderDTO.operationCardBaseDTO.id,
          type: 'edit',
        },
      })
      window.open(routeData.href, '_blank')
      this.handleModalTips()
    },
    handleCheckCraftCard(column) {
      const routeData = this.$router.resolve({
        // name: 'craft-card-manage',
        name: 'operation-card',
        query: {
          ids: column.operationCardHeaderDTO.operationCardBaseDTO.id,
          type: 'check',
        },
      })
      window.open(routeData.href, '_blank')
    },
    handleDeleteCraftCard(column) {
      this.$confirm({
        content: '确认删除卡片?',
        onOk: () => {
          deleteCraftCardMessageById(
            column.operationCardHeaderDTO.operationCardBaseDTO.id
          ).then((res) => {
            if (res.status === 204) {
              this.$message.success('删除成功')
              this.expandedRowKeys = []
              this.getCraftsByParams()
              this.getNotMakeCount()
            }
          })
        },
      })
    },
    // 复制完整父子卡片
    handleCopyIntactCard(column) {
      this.copyCardId = null
      this.copyFatherCardId = column.id
      this.$message.success('复制成功')
    },
    // 复制子卡片
    handleCopyCraftCard(column) {
      this.copyFatherCardId = null
      this.copyCardId = column.operationCardHeaderDTO.operationCardBaseDTO.id
      this.copyCardPartsType = column.operationCardHeaderDTO.craftPlanDTO
        .isParts
        ? 1
        : 0
      this.$message.success('复制成功')
    },
    // 粘贴完整工艺
    handlePasteIntactCard(column) {
      pasteCraftOperation({
        sourceCraftId: this.copyFatherCardId,
        targetCraftId: column.id,
      }).then((res) => {
        if (res.status === 200) {
          this.$message.success('粘贴成功')
          this.getCraftsByParams()
          this.getNotMakeCount()
        }
      })
    },
    // 粘贴卡片
    handlePasteCraftCard(column, isParts, forMainCard) {
      console.log(column)
      const routeData = this.$router.resolve({
        // name: 'craft-card-manage',
        name: 'operation-card',
        query: {
          ids: column.id,
          type: 'paste',
          cardId: this.copyCardId,
          forMainCard: forMainCard,
          isParts,
        },
      })
      window.open(routeData.href, '_blank')
      this.handleModalTips()
    },
    handlePrintCraftCard(keys) {
      const routeData = this.$router.resolve({
        // name: 'craft-card-manage',
        name: 'operation-card-print',
        query: {
          ids: keys.join(','),
          type: 'batchPrint',
        },
      })
      window.open(routeData.href, '_blank')
    },
    handleModalTips() {
      const that = this
      this.$confirm({
        title: '卡片是否编辑完成？',
        width: 600,
        content: '',
        okText: '已完成',
        cancelText: '取消',
        onOk() {
          that.$destroyAll()
          that.expandedRowKeys = []
          that.getCraftsByParams()
        },
        onCancel() { },
      })
    },
    handleApproveCard(column) {
      this.approveId = column.operationCardHeaderDTO.operationCardBaseDTO.id
      this.approveCardModalVisible = true
    },
    handleApproveModalCanceled() {
      this.approveCardModalVisible = false
    },
    handleApproveModalConfirmed() {
      this.approveCardModalVisible = false
      this.getCraftCardData(this.expandedRowKeys[0])
      this.getCraftsByParams()
    },

    handleBatchExportCraft() {
      const routeData = this.$router.resolve({
        name: 'operation-card',
        query: {
          ids: this.selectedRowKeys.join(','),
          type: 'batchAdd',
        },
      })
      window.open(routeData.href, '_blank')
      this.handleModalTips()
    },
    handleBatchCopyPartsCraft(ids) {
      this.batchCopyIds = ids
      this.$message.success('批量复制成功')
    },
    handleBatchPasteCraftCard(column) {
      const that = this
      this.$confirm({
        title: `是否确认插入粘贴分件工艺至${column.craftCode}`,
        width: 600,
        content: '',
        okText: '确定',
        cancelText: '取消',
        onOk() {
          that.$destroyAll()
          that.loading = true
          batchPastePartsCraft({
            sourceCraftIds: that.batchCopyIds,
            targetCraftId: column.id,
          })
            .then(() => {
              that.$message.success('批量粘贴成功')
              that.selectedRowKeys = []
              that.getCraftsByParams()
              that.getNotMakeCount()
            })
            .finally(() => {
              that.loading = false
            })
        },
        onCancel() { },
      })
    },
    handleFileChange(info) {
      this.loading = true
      console.log(info)
      if (info.file.status === 'done') {
        if (info.file.response) {
          this.loading = false
          this.$message.success('导入成功')
          this.getCraftsByParams()
          this.getNotMakeCount()
        }
      } else if (info.file.status === 'error') {
        console.log(info)
        this.$message.error(info.file.response.apierror.message)
        this.loading = false
      }
    },
    handleAddTaskCard(column) {
      getRepairTasks(column.planId).then((res) => {
        if (!res.data.length) {
          this.$notify({
            title: '相关任务不存在',
            dangerouslyUseHTMLString: true,
            type: 'warning',
          })

          return
        } else {
          if (res.data.length === 1) {
            const value = res.data[0]
            const routeData = this.$router.resolve({
              name: 'operation-card',
              query: {
                ids: column.id,
                type: 'taskCard',
                isParts: column.isParts,
                taskId: value.id,
                taskCode: value.code,
                taskDeliveryTime: value.taskDeliveryTime,
              },
            })
            window.open(routeData.href, '_blank')
            this.handleModalTips()
            return
          }
          this.taskCardColumn = column
          this.taskCardCraftId = column.id
          this.taskCardPlanId = column.planId
          this.taskCardChooseModalVisible = true
        }
      })
    },
    handleTaskModalConfirmed(value) {
      console.log(value)
      this.taskCardChooseModalVisible = false

      const routeData = this.$router.resolve({
        name: 'operation-card',
        query: {
          ids: this.taskCardCraftId,
          type: 'taskCard',
          isParts: this.taskCardColumn.isParts,
          taskId: value.id,
          taskCode: value.code,
          taskDeliveryTime: value.taskDeliveryTime,
        },
      })
      window.open(routeData.href, '_blank')
      this.handleModalTips()
    },
    handleTaskModalCanceled() {
      this.taskCardChooseModalVisible = false
    },
    async handleDownloadFile(id, fileName) {
      this.loading = true
      let url = `/crafts/${id}/file`
      await handleDownLoad(url, '', fileName)
      this.loading = false
    },
    handleCustomRow(record) {
      return {
        on: {
          dblclick: () => {
            this.handleUpdateCraftCard(record)
          },
        },
      }
    },
    handleCreateTaskCraftCard(column) {
      getTaskProcessRoute(column.operationCardHeaderDTO.craftPlanDTO.planId).then((res) => {
        const info = res.data
        if (info.length === 1) {
          const routeData = this.$router.resolve({
            name: 'operation-card',
            query: {
              ids: column.operationCardHeaderDTO.operationCardBaseDTO.id,
              planId: column.operationCardHeaderDTO.craftPlanDTO.planId,
              type: 'copyAdd',
              versionType: 'task',
              chooseTaskId: info[0].taskInfo.taskId,
            },
          })
          window.open(routeData.href, '_blank')
        } else if (info.length > 1) {
          this.actualColumn = column
          this.taskCraftChooseModalVisible = true
        }
      })
    },
    handleCraftTaskModalConfirmed(value) {
      console.log(value)
      this.taskCraftChooseModalVisible = false
      const routeData = this.$router.resolve({
        name: 'operation-card',
        query: {
          ids: this.actualColumn.operationCardHeaderDTO.operationCardBaseDTO.id,
          planId: this.actualColumn.operationCardHeaderDTO.craftPlanDTO.planId,
          type: 'copyAdd',
          versionType: 'task',
          chooseTaskId: value,
        },
      })
      window.open(routeData.href, '_blank')
      this.handleModalTips()
    },
    handleCraftTaskModalCanceled() {
      this.taskCraftChooseModalVisible = false
    },
    handleBatchFileChange(info) {
      this.uploading = true

      if (info.file.status === 'done') {
        if (info?.file?.response) {
          const invalidInfos = info.file.response.invalidMessages || []

          if (invalidInfos.length) {
            const h = this.$createElement
            message.error({
              content: h('div', {}, invalidInfos.map(
                info => h('p', info))),
            });
          } else {
            this.$message.success('导入成功')
            this.getCraftsByParams()
            this.getNotMakeCount()
          }

          this.uploading = false
        }
      } else if (info.file.status === 'error') {
        this.$message.error(info.file.response.apierror.message)
        this.uploading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.app-container {
  position: relative;

  .tip-box {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .top-tip {
      margin-left: 20px;
    }

    .download {
      display: flex;
      //flex-direction: column;
      margin-top: 2px;

      .downloadBtn {
        margin-right: 20px;
      }
    }
  }

  .refresh {
    width: 80px;
    cursor: pointer;
    // position: absolute;
    // z-index: 1;
    // top: 95px;
    // left: 253px;
  }

  .selected-wrapper {
    height: 25px;
    line-height: 25px;
    margin-bottom: 8px;
  }

  .batch-operate-link {
    padding-left: 20px;
    cursor: pointer;
    color: #409eff;
  }

  .file-wrapper {
    width: 140px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .filename-wrapper {
      display: inline-block;
      width: 120px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-right: 10px;

      span {
        color: #67c23a;
      }
    }

    .filename-delete {
      color: #ff4d4f;
      margin-right: 8px;
    }
  }

  .file-link {
    color: #409eff;
    cursor: pointer;
  }

  .craft-download-button {
    position: absolute;
    right: 20px;
    top: 75px;
  }

  .operation {
    .operation-btn {
      cursor: pointer;
      color: #409eff;
    }
  }

  /deep/ .no-expand {
    .ant-table-row-expand-icon {
      display: none;
    }
  }

  .export-craft {
    float: right;
    top: -50px;
    right: 20px;
  }

  /deep/ .ant-table-pagination,
  .ant-pagination {
    float: left;
  }

  .top-dowload-btn {
    position: absolute;
    left: 245px;
    z-index: 1;
    display: flex;
    justify-content: space-between;
  }

  /deep/ .el-button {
    margin-left: 0px;
  }
}
</style>
