<template>
  <div class="card-container">
    <div id="craft-card-print">
      <div v-for="taskData in taskDatas" :key="taskData" style="page-break-after:always">
        <a-descriptions class="card-title" bordered>
          <a-descriptions-item label="工序流转卡（代工序检验记录）" :span="3">
            <span v-if="taskData.code===null">暂无编号</span>
            <vue-qrcode v-else margin="0" scale="2" :value="taskData.code" />
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions bordered>
          <a-descriptions-item label="客户">***</a-descriptions-item>
          <a-descriptions-item label="合同交期">{{ taskData.deadline }}</a-descriptions-item>
          <a-descriptions-item label="生产日期">{{ taskData.startTime }}</a-descriptions-item>
        </a-descriptions>
        <a-descriptions bordered layout="vertical" :column="5">
          <a-descriptions-item label="任务号">{{ taskData.code }}</a-descriptions-item>
          <a-descriptions-item label="产品名称">{{ taskData.productName }}</a-descriptions-item>
          <a-descriptions-item label="产品图号">{{ taskData.drawingNum }}</a-descriptions-item>
          <a-descriptions-item label="实发材料" />
          <a-descriptions-item label="投产数量">{{ taskData.productionNum }}</a-descriptions-item>
          <a-descriptions-item label="计划填写" />
          <a-descriptions-item label="操作者填写" />
          <a-descriptions-item label="检验员填写" :span="3" />
        </a-descriptions>
        <a-table :columns="columns" :data-source="data" bordered :pagination="false" />
        <div class="footer-form">
          <span>计划：</span>
          <div class="bottom-line" />
          <span>车间主任：</span>
          <div class="bottom-line" />
        </div>
      </div>
    </div>
    <a-button v-print="'craft-card-print'" type="primary" block>打印</a-button>
  </div>
</template>
<script>
import { getCraftCardInfoByIds } from '@/api/task'
import VueQrcode from 'vue-qrcode'
const columns = [
  {
    title: '工序号',
    dataIndex: 'operationNum',
  },
  {
    title: '计划完工期',
    dataIndex: 'planFinishDate',
  },
  {
    title: '工艺路线',
    dataIndex: 'processRoute',
  },
  {
    title: '车间工序',
    dataIndex: 'workshopProcess',
  },
  {
    title: '操作者',
    dataIndex: 'operator',
  },
  {
    title: '交验数',
    dataIndex: 'inspectNum',
  },
  {
    title: '日期',
    dataIndex: 'date',
  },
  {
    title: '合格数',
    dataIndex: 'qualifiedNum',
  },
  {
    title: '利用数',
    dataIndex: 'useNum',
  },
  {
    title: '废品数',
    dataIndex: 'wasteNum',
  },
  {
    title: '质量状况简述',
    dataIndex: 'qualityConditionDesc',
  },
  {
    title: '检验员',
    dataIndex: 'inspector',
  },
  {
    title: '检验日期',
    dataIndex: 'inspectDate',
  },
]

const data = []
for (let recordNum = 0; recordNum < 12; recordNum++) {
  data.push({
    operationNum: recordNum + 1,
  })
}

export default {
  components: {
    VueQrcode,
  },
  data() {
    return {
      data,
      columns,
      taskCode: null,
      taskDatas: [],
    }
  },
  created() {
    const taskIds = this.$route.query.ids
    getCraftCardInfoByIds({
      ids: taskIds,
    }).then((res) => {
      if (res.data) {
        this.taskDatas = res.data
      } else {
        this.$message.error('获取工艺流转卡数据失败，请重试！')
      }
    })
  },
}
</script>
<style scoped>
.card-container {
  width: 50%;
  margin: auto;
}

.card-title >>> .ant-descriptions-item-label {
  font-size: 20px;
}

/deep/ .ant-descriptions-bordered .ant-descriptions-item-label {
  border: 1px solid black;
  text-align: center;
}

/deep/ .ant-descriptions-bordered .ant-descriptions-item-content {
  border: 1px solid black;
  text-align: center;
  font-weight: bold;
}

/deep/ .ant-table-thead th {
  border: 1px solid black;
  padding: 8px 8px;
  text-align: center;
}

/deep/ .ant-table-tbody td {
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  padding: 8px 8px;
  text-align: center;
}

/deep/ .ant-table-content {
  border-right: 1px solid black;
}

.footer-form {
  float: right;
  margin-top: 15px;
  margin-bottom: 20px;
}

.bottom-line {
  width: 100px;
  height: 20px;
  margin-right: 10px;

  display: inline-block;
  border-bottom: 1px solid grey;
}
</style>
