<template>
  <div class="wrap">
    <div id="craft-card-print">
      <div v-for="(item, itemIndex) of batchData" :key="itemIndex">
        <div
          v-for="(card, index) of item.operationCardBodyDTO.craftProcessRouteListDTO"
          :key="index"
          class="card-container"
          style="page-break-after:always"
        >
          <div class="card-header-title">
            <div class="card">
              <img class="img" src="@/assets/logo.png" alt />
              <div class="card-title">工艺卡片</div>
            </div>
            <div v-if="item.operationCardHeaderDTO.craftPlanDTO.planCode" class="code">
              <vue-qrcode margin="0" scale="2" :value="item.operationCardHeaderDTO.craftPlanDTO.planCode" />
            </div>
            <div v-else class="code" />
            <div class="info">
              <div class="unit">单 位</div>
              <div class="name">重庆市DBR刀具有限公司</div>
            </div>
          </div>
          <div class="card-header-production">
            <div class="product-item">
              <div class="desc">
                <p>产品名称</p>
              </div>
              <div class="cell">
                <textarea
                  v-model="item.operationCardHeaderDTO.craftProductDTO.craftProductName"
                  :disabled="ifUpdate || ifCheck || ifCopy"
                  requierd
                  maxlength="16"
                  cols="10"
                  :style="{'line-height': 1.2 * pageUnit + 'px' }"
                />
              </div>
            </div>
            <div class="product-item">
              <div class="desc">
                <p>产品图号</p>
              </div>
              <div class="cell">
                <textarea
                  v-model="item.operationCardHeaderDTO.craftProductDTO.craftDrawingNum"
                  :disabled="ifUpdate || ifCheck || ifCopy"
                  maxlength="22"
                  rows="2"
                  cols="10"
                  :style="{'line-height': 1.2 * pageUnit + 'px' }"
                />
              </div>
            </div>
            <div class="product-item">
              <div class="desc">
                <p>产品规格</p>
              </div>
              <div class="cell">
                <textarea
                  v-model="item.operationCardHeaderDTO.craftProductDTO.craftSpecification"
                  :disabled="ifUpdate || ifCheck || ifCopy"
                  maxlength="18"
                  rows="2"
                  cols="10"
                  :style="{'line-height': 1.2 * pageUnit + 'px' }"
                />
              </div>
            </div>
            <div class="product-item">
              <div class="desc">
                <p style="letter-spacing: 0px;">工艺文件编号</p>
              </div>
              <div class="cell">
                <textarea
                  v-model="item.operationCardHeaderDTO.operationCardBaseDTO.craftCode"
                  :disabled="ifCheck || ifCopy"
                  maxlength="17"
                  rows="2"
                  cols="10"
                  :style="{'line-height': 1.2 * pageUnit + 'px' }"
                />
              </div>
            </div>
            <div class="product-item">
              <div class="desc">
                <p style="letter-spacing: 5px;">计划号</p>
              </div>
              <div class="cell">
                <textarea
                  v-model="item.operationCardHeaderDTO.craftPlanDTO.planCode"
                  :disabled="ifUpdate || ifCheck || $route.query.isParts === '0' || ifPaste || ifCopy"
                  maxlength="16"
                  rows="2"
                  cols="10"
                  :style="{'line-height': 1.2 * pageUnit + 'px' }"
                />
              </div>
            </div>
          </div>
          <div class="card-header-material">
            <div class="material-item">
              <div class="desc">
                <p>材料</p>
              </div>
              <div class="cell">
                <!-- <textarea
									v-model="card.materialName"
									rows="2"
									cols="10"
									:style="{'line-height': card.materialName.length > 9 ? 0.6 * pageUnit + 'px' : 1.2 * pageUnit + 'px' }"
                />-->
                <a-select
                  :disabled="ifCheck"
                  class="materialSelect"
                  :value="item.operationCardHeaderDTO.craftMaterialDTO.materialName"
                  show-search
                  :default-active-first-option="false"
                  style="width: 96%;margin-left:  5px;margin-top: 3px;"
                  placeholder="请输入材料名"
                  :show-arrow="false"
                  :filter-option="false"
                  :not-found-content="fetching ? undefined : null"
                  @search="fetchMaterialByName"
                  @change="(value, option) => handleMaterialNameChange(value, option, index)"
                >
                  <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                  <a-select-option v-for="material in materials" :key="material.id">{{ material.materialName }}({{ material.materialCode }})</a-select-option>
                </a-select>
              </div>
            </div>
            <div class="material-item">
              <div class="desc">
                <p>材料牌号</p>
              </div>
              <div class="cell">
                <a-select
                  :disabled="ifCheck"
                  class="materialSelect"
                  :value="item.operationCardHeaderDTO.craftMaterialDTO.materialCode"
                  show-search
                  :default-active-first-option="false"
                  style="width: 96%;margin-left:  5px;margin-top: 3px;border: none;"
                  placeholder="请输入材料图号"
                  :show-arrow="false"
                  :filter-option="false"
                  :not-found-content="fetching ? undefined : null"
                  @search="fetchMaterialByCode"
                  @change="(value, option) => handleMaterialNameChange(value, option, index)"
                >
                  <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                  <a-select-option v-for="material in materials" :key="material.id">{{ material.materialName }}({{ material.materialCode }})</a-select-option>
                </a-select>
              </div>
            </div>
            <div class="material-item">
              <div class="desc">
                <p>硬度</p>
              </div>
              <div class="cell">
                <!-- <textarea v-model="item.operationCardHeaderDTO.craftProductDTO.operationHardness" :disabled="ifCheck"
                  rows="2" cols="10" maxlength="10"
                  :style="{ 'line-height': item.operationCardHeaderDTO.craftProductDTO && item.operationCardHeaderDTO.craftProductDTO.operationHardness && item.operationCardHeaderDTO.craftProductDTO.operationHardness.length > 9 ? 0.6 * pageUnit + 'px' : 1.2 * pageUnit + 'px' }" /> -->
              <a-select
                v-model="item.operationCardHeaderDTO.craftProductDTO.operationHardness"
                :show-arrow="false"
                :allow-clear="true"
                class="materialSelect"
                style="width: 96%;margin-left:  5px;margin-top: 3px;"
                :disabled="ifCheck"
                show-search
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @search="fetchHardness"
              >
                <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                <a-select-option
                  v-for=" hardness in hardnessList"
                  :key="hardness.parameterValue"
                  :value="hardness.parameterValue"
                >{{ hardness.parameterValue }}</a-select-option>
                <!-- <a-select-option
                  v-for=" hardness in hardnessList"
                  :key="hardness.parentParameterId"
                  :value="hardness.parameterValue"
                >{{ hardness.parameterValue }}</a-select-option>-->
              </a-select>
              </div>
            </div>
            <div class="material-item">
              <div class="desc">
                <p>版本号</p>
              </div>
              <div class="cell">
                <a-select
                  v-model="item.operationCardHeaderDTO.operationCardBaseDTO.operationCardVersion"
                  class="materialSelect userSelect versionSelect"
                  :disabled="ifCheck || ifUpdate"
                  style="width: 90%;text-align:center;border: none;"
                  option-filter-prop="children"
                >
                  <a-select-option v-for="i in versionList" :key="(i + 9).toString(36) + i" :value="i">{{ i }}</a-select-option>
                </a-select>
                <!-- <textarea
									:disabled="ifCheck"
									v-model="cardHeaderMessage.operationCardBaseDTO.operationCardVersion"
									rows="2"
									cols="10"
									:style="{'line-height': cardHeaderMessage.operationCardBaseDTO.operationCardVersion.length > 9 ? 0.6 * pageUnit + 'px' : 1.2 * pageUnit + 'px' }"
                />-->
              </div>
            </div>
            <div class="material-item">
              <div class="desc">
                <p>备注</p>
              </div>
              <div class="cell">
                <textarea v-model="item.operationCardHeaderDTO.operationCardBaseDTO.comment" :disabled="ifCheck" rows="2" cols="10" />
              </div>
            </div>
          </div>
          <div id="processRoute" :ref="processRoute + index" class="card-header-processRoute">
            <div class="card-header-processRoute-header">
              <div class="num">工序号</div>
              <div class="type">工种</div>
              <div class="content">
                工 序 名 称 及 内 容
                <template v-if="index + 1 === item.operationCardBodyDTO.craftProcessRouteListDTO.length">
                  <a-icon v-if="!ifPrint && !ifCheck" type="plus-circle" theme="twoTone" @click="handleAddCraft(index)" />
                </template>
              </div>
              <div class="time-info">
                <div class="all-time">
                  <div class="desc">
                    <p>总加工工时</p>
                  </div>
                  <div class="data">
                    <p>{{ item.operationCardHeaderDTO.operationCardBaseDTO.totalProcessHour }}</p>
                  </div>
                </div>
                <div class="stand-time">
                  <div class="ot">
                    <p>核定加工工时</p>
                  </div>
                  <div class="date">
                    <p>核定转出日期</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-header-processRoute-content">
              <table :ref=" 'processRouteContent' + index + itemIndex">
                <tbody>
                  <tr v-for="(route) of card.processRoute" :key="route.id" class="process">
                    <td class="num">
                      <template>{{ route.sequenceNum }}</template>
                    </td>
                    <td 
                      class="type"
                      :style="{'font-size': route.operationName && route.operationName.length > 3 ? '12px' : '18px', 'line-height': route.operationName && route.operationName.length > 3 ? '14px' : '18px' }"
                    >
                    {{ route.operationName }}</td>
                    <td class="content">{{ route.content }}</td>
                    <td class="hour">{{ route.verifyProcessHour }}</td>
                    <td class="date">{{ route.verifyTransferOutTime }}</td>
                  </tr>
                </tbody>
              </table>
              <table>
                <tbody v-for="(item, newindex) of new Array(card.nullProcessRouteNum)" :key="newindex">
                  <tr class="process null">
                    <td class="num" />
                    <td class="type" />
                    <td class="content" />
                    <td class="hour" />
                    <td class="date" />
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-header-bottom">
            <div class="row">
              <div class="column-1 desc" />
              <div class="column-2 desc" />
              <div class="column-3 desc" />
              <div class="column-4 desc" />
              <div class="column-5 desc" />
              <div class="column-6 desc" />
              <div class="column-7 desc" />
              <div class="column-8 desc" />
              <div class="column-9 desc" />
              <div class="column-10 desc" />
            </div>
            <div class="row">
              <div class="column-1 desc" />
              <div class="column-2 desc" />
              <div class="column-3 desc" />
              <div class="column-4 desc" />
              <div class="column-5 desc" />
              <div class="column-6 desc" />
              <div class="column-7 desc" />
              <div class="column-8 desc" />
              <div class="column-9 desc" />
              <div class="column-10 desc" />
            </div>
            <div class="row">
              <div class="column-1 desc">标记</div>
              <div class="column-2 desc">处数</div>
              <div class="column-3 desc">更改单号</div>
              <div class="column-4 desc">签名</div>
              <div class="column-5 desc">日期</div>
              <div class="column-6 desc">标记</div>
              <div class="column-7 desc">处数</div>
              <div class="column-8 desc">更改单号</div>
              <div class="column-9 desc">签名</div>
              <div class="column-10 desc">日期</div>
            </div>
            <div class="row">
              <div class="column-1 desc" style="border-bottom: none; border-right: none;" />
              <div class="column-2 desc" style="border-left: none; border-bottom: none;" />
              <div class="column-3 desc">编制</div>
              <div class="column-4 desc">
                <a-select
                  v-model="item.operationCardFooterDTO.craftDesignDTO.designerId"
                  class="materialSelect userSelect"
                  :disabled="ifCheck"
                  style="width: 96%;height: 94%;margin-left: 2px;text-align:center;border: none;"
                  show-search
                  option-filter-prop="children"
                  :filter-option="filterUser"
                >
                  <a-select-option v-for="user in userList" :key="user.id" :value="user.id">{{ user.realName }}</a-select-option>
                </a-select>
              </div>
              <div class="column-5 desc">
                <a-date-picker
                  v-model="item.operationCardFooterDTO.craftDesignDTO.designTime"
                  style="width: 96%;height: 98%;margin-left: 2px;text-align:center;border: none;"
                  :disabled="ifCheck"
                  placeholder
                  value-format="YYYY-MM-DD HH:mm:ss"
                >
                  <span slot="suffixIcon" />
                </a-date-picker>
              </div>
              <div class="column-6 desc" v-html="'标&#8197;&#8197;准&#8197;&#8197;化'" />
              <div class="column-7 desc">
                <a-select
                  v-model="item.operationCardFooterDTO.craftStandardDTO.standardId"
                  class="materialSelect userSelect"
                  :disabled="ifCheck"
                  style="width: 96%;height: 98%;margin-left: 2px;text-align:center;border: none;"
                  show-search
                  option-filter-prop="children"
                  :filter-option="filterUser"
                >
                  <a-select-option v-for="user in userList" :key="user.id" :value="user.id">{{ user.realName }}</a-select-option>
                </a-select>
              </div>
              <div class="column-8 desc">
                <a-date-picker
                  v-model="item.operationCardFooterDTO.craftStandardDTO.standardTime"
                  :disabled="ifCheck"
                  placeholder
                  value-format="YYYY-MM-DD HH:mm:ss"
                >
                  <span slot="suffixIcon" />
                </a-date-picker>
              </div>
              <div class="column-combine desc">阶段标记</div>
            </div>
            <div class="row">
              <div class="column-1 desc" style="border-bottom: none;" />
              <div class="column-2 desc" style="border-left: none; border-bottom: none;" />
              <div class="column-3 desc">校对</div>
              <div class="column-4 desc">
                <a-select
                  v-model="item.operationCardFooterDTO.craftProofreadDTO.collatorId"
                  class="materialSelect userSelect"
                  :disabled="ifCheck"
                  style="width: 96%;height: 98%;margin-left: 2px;text-align:center;border: none;"
                  show-search
                  option-filter-prop="children"
                  :filter-option="filterUser"
                >
                  <a-select-option v-for="user in userList" :key="user.id" :value="user.id">{{ user.realName }}</a-select-option>
                </a-select>
              </div>
              <div class="column-5 desc">
                <a-date-picker
                  v-model="item.operationCardFooterDTO.craftProofreadDTO.proofreadTime"
                  :disabled="ifCheck"
                  style="width: 96%;height: 98%;margin-left: 2px;text-align:center;border: none;"
                  placeholder
                  value-format="YYYY-MM-DD HH:mm:ss"
                >
                  <span slot="suffixIcon" />
                </a-date-picker>
              </div>
              <div class="column-6 desc">质量会签</div>
              <div class="column-7 desc">
                <a-select
                  v-model="item.operationCardFooterDTO.craftQualityDTO.qualityId"
                  class="materialSelect userSelect"
                  :disabled="ifCheck"
                  style="width: 96%;height: 98%;margin-left: 2px;text-align:center;border: none;"
                  show-search
                  option-filter-prop="children"
                  :filter-option="filterUser"
                >
                  <a-select-option v-for="user in userList" :key="user.id" :value="user.id">{{ user.realName }}</a-select-option>
                </a-select>
              </div>
              <div class="column-8 desc">
                <a-date-picker
                  v-model="item.operationCardFooterDTO.craftQualityDTO.qualityTime"
                  :disabled="ifCheck"
                  placeholder
                  value-format="YYYY-MM-DD HH:mm:ss"
                >
                  <span slot="suffixIcon" />
                </a-date-picker>
              </div>
              <div class="column-9 desc">
                <div class="left">S</div>
                <div class="right" />
              </div>
              <div class="column-10 desc">
                <div class="left" />
                <div class="right" />
              </div>
            </div>
            <div class="row">
              <div class="column-1 desc" />
              <div class="column-2 desc" style="border-left: none;" />
              <div class="column-3 desc">审核</div>
              <div class="column-4 desc">
                <a-select
                  v-model="item.operationCardFooterDTO.craftAuditDTO.auditorId"
                  class="materialSelect userSelect"
                  :disabled="ifCheck"
                  style="width: 100%;text-align:center;border: none;"
                  show-search
                  option-filter-prop="children"
                  :filter-option="filterUser"
                >
                  <a-select-option v-for="user in userList" :key="user.id" :value="user.id">{{ user.realName }}</a-select-option>
                </a-select>
              </div>
              <div class="column-5 desc">
                <a-date-picker
                  v-model="item.operationCardFooterDTO.craftAuditDTO.auditTime"
                  :disabled="ifCheck"
                  style="width: 96%;margin-left: 2px;text-align:center;border: none;"
                  placeholder
                  value-format="YYYY-MM-DD HH:mm:ss"
                >
                  <span slot="suffixIcon" />
                </a-date-picker>
              </div>
              <div class="column-6 desc" v-html="'批&#12288;&#12288;准'" />
              <div class="column-7 desc">
                <a-select
                  v-model="item.operationCardFooterDTO.craftApproveDTO.approverId"
                  class="materialSelect userSelect"
                  :disabled="true"
                  style="width: 96%;margin-left: 2px;text-align:center;border: none;"
                  show-search
                  option-filter-prop="children"
                  :filter-option="filterUser"
                >
                  <a-select-option v-for="user in userList" :key="user.id" :value="user.id">{{ user.realName }}</a-select-option>
                </a-select>
              </div>
              <div class="column-8 desc">
                <a-date-picker
                  v-model="item.operationCardFooterDTO.craftApproveDTO.approveTime"
                  :disabled="true"
                  style="width: 96%;height: 98%;margin-left: 2px;text-align:center;border: none;"
                  placeholder
                  value-format="YYYY-MM-DD HH:mm:ss"
                >
                  <span slot="suffixIcon" />
                </a-date-picker>
              </div>
              <div class="column-9 desc">共 {{ item.operationCardHeaderDTO.operationCardBaseDTO.totalPages }} 页</div>
              <div class="column-10 desc">第 {{ index + 1 }} 页</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!ifPrint" class="card-footer">
        <!-- <a-button style="margin-left: 100px;" @click="handleAddCard">增加卡片</a-button> -->
        <a-button v-print="'craft-card-print'" style="margin-left: 100px;" type="primary" @click="handlePrint">打印</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import VueQrcode from 'vue-qrcode'
import CraftProcessEditModal from '@/components/CraftProcessEditModal'
import CraftTemplateChoose from '@/components/CraftTemplateChoose'
import * as R from 'ramda'
import { getBatchPrintCardInfo } from '@/api/craft'
import {
  getMaterialByMaterialName,
  getMaterialByMaterialCode,
} from '@/api/material'
import { getAllUsersWithDeleted } from '@/api/user'
import debounce from 'lodash/debounce'

export default {
  components: {
    VueQrcode,
    CraftProcessEditModal,
    CraftTemplateChoose,
  },
  data() {
    this.lastFetchId = 0
    this.fetchMaterialByName = debounce(this.fetchMaterialByName, 800)
    this.fetchMaterialByCode = debounce(this.fetchMaterialByCode, 800)
    return {
      pageUnit: 54,
      userList: [],
      versionList: [
        '00',
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
      ],
      cardData: [],
      cardHeaderMessage: {},
      cardFooterMessage: {},
      cardBodyMessage: {},
      ifCheck: false, // 是否查看状态
      handleCardIds: '',
      handleType: '',
      fetching: false, // 材料搜索
      materials: {},
      ifPrint: false,
      cardType: 1, // 1为主卡片 0为分卡片

      batchData: [],
    }
  },
  async mounted() {
    await this.getCraftData()
    this.getAllUsersWithDeleted()
    this.initNullColumn()
  },
  methods: {
    getCraftData() {
      return new Promise((resolve, reject) => {
        const { ids, type } = this.$route.query
        this.handleCardIds = ids
        this.handleType = type
        if (ids) {
          if (type === 'batchPrint') {
            this.ifCheck = true
            getBatchPrintCardInfo(ids).then((res) => {
              this.batchData = res.data
              if (this.batchData.length) {
                this.batchData.map((card) => {
                  if (
                    !card.operationCardBodyDTO.craftProcessRouteListDTO.length
                  ) {
                    card.operationCardBodyDTO.craftProcessRouteListDTO.push({
                      pageSeq: 1,
                      processRoute: [],
                    })
                  }
                })
              }
              resolve()
            })
          }
          // getCraftCardMessage(ids).then(res => {
          //   this.cardData = res.data
          //   this.processRoute = res.data.processRoute
          // })
        }
      })
    },
    getAllUsersWithDeleted() {
      getAllUsersWithDeleted(this.userQueryParams).then((res) => {
        if (res.data && res.data.accountList) {
          this.userList = R.clone(res.data.accountList)
        }
      })
    },
    filterUser(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    fetchMaterialByName(value) {
      if (R.isEmpty(value) || R.isEmpty(value.trim())) {
        return
      }
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.materials = {}
      this.fetching = true
      getMaterialByMaterialName(value).then((result) => {
        if (fetchId !== this.lastFetchId) {
          // for fetch callback order
          return
        }
        this.materials = result.data
        this.fetching = false
      })
    },
    fetchMaterialByCode(value) {
      if (R.isEmpty(value) || R.isEmpty(value.trim())) {
        return
      }
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.materials = {}
      this.fetching = true
      getMaterialByMaterialCode(value).then((result) => {
        if (fetchId !== this.lastFetchId) {
          // for fetch callback order
          return
        }
        this.materials = result.data
        this.fetching = false
      })
    },
    handleMaterialNameChange(id, option, index) {
      this.materials.map((val) => {
        if (val.id === id) {
          console.log(val)
          this.cardHeaderMessage.craftMaterialDTO.materialName =
            val.materialName
          this.cardHeaderMessage.craftMaterialDTO.materialCode =
            val.materialCode
          this.cardHeaderMessage.craftMaterialDTO.materialId = val.id
        }
      })
      Object.assign(this, {
        materials: [],
        fetching: false,
      })
    },
    handleProcessRouteSeq() {
      let _seq = 0
      this.cardBodyMessage.craftProcessRouteListDTO.map((routePage) => {
        routePage.processRoute.map((route) => {
          _seq += 1
          route.sequenceNum = _seq
          console.log(route.sequenceNum)
        })
      })
      console.log(this.cardBodyMessage.craftProcessRouteListDTO)
    },

    handlePrint() {
      // 打印前先隐藏部分Dom
      const that = this
      this.ifPrint = true
      setTimeout(() => {
        that.ifPrint = false
      }, 100)
    },
    initNullColumn() {
      this.batchData.map((item, itemIndex) => {
        item.operationCardBodyDTO.craftProcessRouteListDTO.map(
          (card, index) => {
            const height =
              this.$refs[`processRouteContent${index}${itemIndex}`][0]
                .offsetHeight
            card.nullProcessRouteNum = Math.floor((930 - height) / 43)
          }
        )
      })
    },
  },
}
</script>

<style lang="scss" scoped>
$unit: 54px;
.card-container {
  width: 19.83 * $unit;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  .card-header-title {
    display: flex;
    .card {
      box-sizing: border-box;
      width: 12.84 * $unit;
      height: 1.6 * $unit;
      border: 1px solid black;
      border-bottom: none;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 28px;
      letter-spacing: 30px;
      position: relative;
      .template-btn {
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
      }
      .img {
        width: 120px;
        height: 70px;
        background: url(~@/assets/logo.png);
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 20px;
      }
      .card-title {
        position: relative;
        .tag {
          letter-spacing: 2px;
          position: absolute;
          top: 2px;
          right: -25px;
        }
      }
    }
    .code {
      box-sizing: border-box;
      width: 2.38 * $unit;
      height: 1.6 * $unit;
      border: 1px solid black;
      border-left: none;
      border-bottom: none;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .info {
      box-sizing: border-box;
      width: 4.63 * $unit;
      height: 1.6 * $unit;
      border: 1px solid black;
      border-left: none;
      border-bottom: none;
      display: flex;
      flex-direction: column;
      font-weight: bold;
      font-size: 18px;
      .unit {
        flex: 1;
        box-sizing: border-box;
        text-align: center;
        line-height: 0.8 * $unit;
        border-bottom: 1px solid black;
      }
      .name {
        flex: 1;
        text-align: center;
        line-height: 0.8 * $unit;
      }
    }
  }
  .card-header-production {
    display: flex;
    .product-item {
      .desc {
        box-sizing: border-box;
        width: 4.1 * $unit;
        height: 1.2 * $unit;
        border: 1px solid black;
        border-right: none;
        border-bottom: none;
        p {
          width: 100%;
          text-align: center;
          font-weight: bold;
          font-size: 18px;
          line-height: 1.2 * $unit;
          letter-spacing: 10px;
        }
      }
      .cell {
        box-sizing: border-box;
        width: 4.1 * $unit;
        height: 1.2 * $unit;
        border: 1px solid black;
        border-right: none;
        textarea {
          width: 100%;
          height: 100%;
          background: none;
          text-align: center;
          padding: 0;
          outline-style: none;
          border: none;
          resize: none;
          overflow: hidden;
          font-size: 15px;
          font-weight: bold;
        }
      }
      &:nth-child(1) {
        .desc {
          width: 4.1 * $unit;
        }
        .cell {
          width: 4.1 * $unit;
        }
      }
      &:nth-child(2) {
        .desc {
          width: 6.5 * $unit;
        }
        .cell {
          width: 6.5 * $unit;
        }
      }
      &:nth-child(3) {
        .desc {
          width: 4.6 * $unit;
        }
        .cell {
          width: 4.6 * $unit;
        }
      }
      &:nth-child(4) {
        .desc {
          width: 2.3 * $unit;
        }
        .cell {
          width: 2.3 * $unit;
        }
      }
      &:nth-child(5) {
        border-right: 1px solid black;
        .desc {
          width: 2.3 * $unit;
        }
        .cell {
          width: 2.3 * $unit;
        }
      }
    }
  }
  .card-header-material {
    display: flex;
    .material-item {
      display: flex;
      .desc {
        box-sizing: border-box;
        width: 2.1 * $unit;
        height: 1.2 * $unit;
        border: 1px solid black;
        border-top: none;
        p {
          text-align: center;
          font-weight: bold;
          font-size: 16px;
          line-height: 1.2 * $unit;
        }
      }
      .cell {
        overflow: hidden;
        box-sizing: border-box;
        width: 4.1 * $unit;
        height: 1.2 * $unit;
        border: 1px solid black;
        border-left: none;
        border-right: none;
        border-top: none;

        textarea {
          width: 100%;
          height: 100%;
          background: none;
          text-align: center;
          padding: 0;
          outline-style: none;
          border: none;
          resize: none;
          overflow: hidden;
          font-size: 15px;
          font-weight: bold;
        }
      }
      &:nth-child(1) {
        .desc {
          width: 1.16 * $unit;
        }
        .cell {
          width: 2.94 * $unit;
        }
      }
      &:nth-child(2) {
        .desc {
          width: 2 * $unit;
        }
        .cell {
          width: 4.5 * $unit;
        }
      }
      &:nth-child(3) {
        .desc {
          width: 2 * $unit;
        }
        .cell {
          width: 2.6 * $unit;
        }
      }
      &:nth-child(4) {
        .desc {
          width: 1 * $unit;
        }
        .cell {
          width: 1.31 * $unit;
        }
      }
      &:nth-child(5) {
        border-right: 1px solid black;
        .desc {
          width: 1.2 * $unit;
        }
        .cell {
          width: 1.1 * $unit;
        }
      }
    }
  }
  .card-header-processRoute {
    &-header {
      width: 19.83 * $unit;
      display: flex;
      position: relative;
      font-weight: bold;
      font-size: 18px;
      div {
        box-sizing: border-box;
        text-align: center;
      }
      .num {
        width: 1.13 * $unit;
        height: 1.5 * $unit;
        border: 1px solid black;
        border-top: none;
        border-right: none;
        writing-mode: vertical-rl;
        text-align: center;
        line-height: 1.13 * $unit;
      }
      .type {
        width: 1.3 * $unit;
        height: 1.5 * $unit;
        border: 1px solid black;
        border-top: none;
        border-right: none;
        line-height: 1.5 * $unit;
      }
      .content {
        width: 12.88 * $unit;
        height: 1.5 * $unit;
        border: 1px solid black;
        border-top: none;
        border-right: none;
        line-height: 1.5 * $unit;
        letter-spacing: 15px;
      }
      .time-info {
        width: 4.68 * $unit;
        height: 1.5 * $unit;
        border: 1px solid black;
        border-top: none;
        display: flex;
        flex-direction: column;
        p {
          font-size: 18px;
          margin-bottom: 0px;
        }
        .all-time {
          flex: 1;
          display: flex;
          border-bottom: 1px solid black;
          .desc {
            flex: 1;
            border-right: 1px solid black;
            line-height: 0.8 * $unit;
          }
          .data {
            flex: 1;
            line-height: 0.8 * $unit;
          }
        }
        .stand-time {
          flex: 1;
          display: flex;
          overflow: hidden;
          .ot {
            flex: 1;
            line-height: 0.7 * $unit;
            border-right: 1px solid black;
          }
          .date {
            flex: 1;
            line-height: 0.7 * $unit;
          }
        }
      }
      .operation {
        width: 3 * $unit;
        height: 1.5 * $unit;
        border: 1px solid black;
        border-left: none;
        border-bottom: none;
        // position: absolute;
        // top: -1px;
        // right: -3 * $unit;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    &-content {
      table {
        table-layout: fixed !important;
      }
      td {
        text-align: center;
      }

      .process {
        width: 19.83 * $unit;
        min-height: 0.8 * $unit;
        line-height: 0.7 * $unit;
        position: relative;
        font-weight: bold;
        .num {
          width: 1.14 * $unit;
          height: 0.8 * $unit;
          box-sizing: border-box;
          border: 1px solid black;
          border-top: none;
          border-right: none;
          writing-mode: horizontal-tb;
          text-align: center;
        }
        .type {
          width: 1.3 * $unit;
          height: 0.8 * $unit;
          box-sizing: border-box;
          border: 1px solid black;
          border-top: none;
          border-right: none;
        }
        .content {
          width: 12.89 * $unit;
          height: 0.8 * $unit;
          padding-left: 5px;
          box-sizing: border-box;
          white-space: break-word;
          border: 1px solid black;
          border-top: none;
          border-right: none;
          line-height: 17px;
          text-align: left;
          word-wrap: break-word;
          word-break: break-all;
          font-size: 18px;
        }
        .hour {
          width: 2.33 * $unit;
          height: 0.8 * $unit;
          box-sizing: border-box;
          border: 1px solid black;
          border-top: none;
        }
        .date {
          font-size: 12px;
          width: 2.32 * $unit;
          height: 0.8 * $unit;
          box-sizing: border-box;
          border: 1px solid black;
          border-top: none;
        }
        .operation {
          width: 3 * $unit;
          height: 0.82 * $unit;
          border: 1px solid black;
          border-left: none;
          // position: absolute;
          // top: -1px;
          // right: -3 * $unit;
        }
      }
      .null {
        .operation {
          border-top: none;
        }
      }
    }
  }
  .card-header-bottom {
    .row {
      display: flex;
      overflow: hidden;
      .desc {
        box-sizing: border-box;
        line-height: 0.8 * $unit;
        border: 1px solid black;
        border-top: none;
        border-right: none;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        .left {
          flex: 1;
          border-right: 1px solid black;
        }
        .right {
          flex: 1;
        }
      }
      .column-1 {
        width: 1.13 * $unit;
        height: 0.8 * $unit;
      }
      .column-2 {
        width: 1.3 * $unit;
        height: 0.8 * $unit;
      }
      .column-3 {
        width: 1.67 * $unit;
        height: 0.8 * $unit;
        overflow: hidden;
      }
      .column-4 {
        width: 2.3 * $unit;
        height: 0.8 * $unit;
        overflow: hidden;
      }
      .column-5 {
        width: 2.4 * $unit;
        height: 0.8 * $unit;
        overflow: hidden;
      }
      .column-6 {
        width: 1.9 * $unit;
        height: 0.8 * $unit;
        overflow: hidden;
      }
      .column-7 {
        width: 2.2 * $unit;
        height: 0.8 * $unit;
        overflow: hidden;
      }
      .column-8 {
        width: 2.3 * $unit;
        height: 0.8 * $unit;
        overflow: hidden;
      }
      .column-9 {
        width: 2.3 * $unit;
        height: 0.8 * $unit;
        display: flex;
        justify-content: center;
      }
      .column-10 {
        width: 2.35 * $unit;
        height: 0.8 * $unit;
        display: flex;
        justify-content: center;
        border-right: 1px solid black;
      }
      .column-combine {
        width: 4.65 * $unit;
        height: 0.8 * $unit;
        border-right: 1px solid black;
        letter-spacing: 10px;
      }
    }
  }
}
</style>
<style scoped>
.materialSelect >>> .ant-select-selection {
  border: none;
}
.materialSelect >>> .ant-select-selection--single {
  height: 60px;
}
.materialSelect >>> .ant-select-selection__rendered {
  line-height: 60px;
  font-size: 12px;
  font-weight: bold;
  margin-right: 0px;
  margin-left: 5px;
}

.materialSelect >>> .ant-select-selection-selected-value {
  overflow: unset;
  max-width: unset;
  text-overflow: unset;
  white-space: break-word;
}

/deep/ .ant-select-disabled {
  background: white;
  color: rgba(0, 0, 0, 0.65);
}
/deep/ .ant-select-selection {
  background: white;
}

/deep/ .ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border-color: white;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0);
}

.ant-select-selection:hover {
  border-color: white;
}
.userSelect >>> .ant-select-selection--single {
  height: 40px;
  color: rgba(0, 0, 0, 0.65);
}
.userSelect >>> .ant-select-selection__rendered {
  line-height: 40px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.65);
  display: flex;
  justify-content: center;
}
.userSelect >>> .anticon {
  display: none;
}

.versionSelect >>> .ant-select-selection__rendered {
  line-height: 62px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.65);
}

/deep/ .ant-calendar-picker .ant-input {
  border: none;
  font-weight: bold;
}
/deep/ .ant-input-disabled {
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
}
.card-footer {
  padding-bottom: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 100px;
}
</style>
