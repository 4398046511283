import request from '@/utils/api.request'

/**
 * 分页获取工艺信息
 * @param {object} data
 */
export function getCrafts(data) {
  return request({
    url: `/crafts`,
    method: 'get',
    params: data,
  })
}

/**
 * 根据id获取指定的工艺信息
 * @param {number} id
 */
export function getCraftById(id) {
  return request({
    url: `/crafts/${id}`,
    method: 'get',
  })
}

/**
 * 根据id获取指定工艺的工艺路线信息
 * @param {number} id
 */
export function getProcessRouteByCraftId(id) {
  return request({
    url: `/crafts/${id}/process-route`,
    method: 'get',
  })
}

/**
 * 增加工艺
 * @param {object} data
 */
export function addCraft(data) {
  return request({
    url: `/crafts`,
    method: 'post',
    data,
  })
}

/**
 * 更新指定id的工艺
 * @param {number} id
 * @param {object} data
 */
export function updateCraft(id, data) {
  return request({
    url: `/crafts/${id}`,
    method: 'put',
    data,
  })
}

/**
 * 删除指定id的工艺
 * @param {number} id
 */
export function deleteCraft(id) {
  return request({
    url: `/crafts/${id}`,
    method: 'delete',
  })
}

/**
 * 通过工艺id删除对应的工艺图文件
 * @param {number} id
 */
export function deleteCraftFile(id) {
  return request({
    url: `/crafts/${id}/file`,
    method: 'delete',
  })
}

/**
 * 通过计划id获取对应计划的工艺信息
 * @param {number} id
 */
export function getCraftsByPlan(id) {
  return request({
    url: `/plans/${id}/crafts`,
    method: 'get',
  })
}

/**
 * 通过计划id获取对应计划的任务信息
 * @param {number} id
 */
export function getTasksByPlan(id) {
  return request({
    url: `/plans/${id}/tasks`,
    method: 'get',
  })
}

/**
 * 通过工艺id预览对应的工艺图
 */
export function showCraftPdf(id) {
  return request({
    url: `/crafts/${id}/file`,
    method: 'get',
  })
}

/**
 * 导出工艺
 * @param {*} data
 */
export function exportCrafts(data) {
  return request({
    url: `/crafts/exports`,
    method: 'get',
    responseType: 'blob',
    params: data,
  })
}
/**
 * 获取工艺路线信息导入模板
 */
export function getProcessRouteImportTemplate() {
  return request({
    url: `/crafts/process-route/import-template`,
    method: 'get',
    responseType: 'blob',
  })
}

export function deleteCraftProcessRoute(id) {
  return request({
    url: `/crafts/operation-cards/${id}/process-route/`,
    method: 'delete',
  })
}

/**
 * 根据id列表获取指定的下达计划交接表信息
 */
export function getCraftDisignCardInfoByIds(data) {
  return request({
    url: `/plans/craft-delivery-receipts`,
    method: 'get',
    params: data,
  })
}

/**
 * 新增工艺卡片信息
 */
export function postAddCraftCard(data, hasCraft) {
  return request({
    url: `/crafts/operation-cards?hasCraft=${hasCraft}`,
    method: 'post',
    data,
  })
}

/**
 * 批量新增卡片
 */
export function batchAddCraftCard(data) {
  return request({
    url: `/crafts/operation-cards/copy-batches`,
    method: 'post',
    data,
  })
}

/**
 * 新增工艺卡片版本
 */
export function postAddCraftCardVersion(data) {
  return request({
    url: `/crafts/operation-cards/versions`,
    method: 'post',
    data,
  })
}

/**
 * 获取工艺卡片信息
 */
export function getCraftCardMessage(id) {
  return request({
    url: `/crafts/operation-cards/${id}`,
    method: 'get',
  })
}

/**
 * 获取工艺卡片信息 包括所有版本
 */
export function getCraftCardVersionMessage(id) {
  return request({
    url: `/crafts/${id}/operation-cards`,
    method: 'get',
  })
}

/**
 * 获取工艺卡片信息 包括所有版本
 */
export function getCraftCardVersionMessageByTaskId(taskId) {
  return request({
    url: `/crafts/tasks/${taskId}/operation-cards`,
    method: 'get',
  })
}

/**
 * 根据id更新工艺卡片信息
 */
export function updateCraftCardMessageById(id, data) {
  return request({
    url: `/crafts/operation-cards/${id}`,
    method: 'put',
    data,
  })
}

/**
 * 根据id删除工艺卡片
 */
export function deleteCraftCardMessageById(id) {
  return request({
    url: `/crafts/operation-cards/${id}`,
    method: 'delete',
  })
}

/**
 * 根据id获取工艺相关信息
 */
export function getCraftbaseInfo(ids) {
  return request({
    url: `/crafts/base-info`,
    method: 'get',
    params: ids,
  })
}

/**
 * 根据id获取工艺相关信息
 */
export function approveCard(id, data) {
  return request({
    url: `/crafts/${id}/status/approved`,
    method: 'patch',
    data,
  })
}

/**
 * 批量打印卡片  获取信息接口
 */
export function getBatchPrintCardInfo(ids) {
  return request({
    url: `/crafts/latest-operation-cards?craftIds=${ids}`,
    method: 'get',
  })
}

/**
 *  获取未编制数量
 */
export function getNotMakeCount(data) {
  return request({
    url: `/crafts/not-make-count`,
    method: 'get',
    params: data,
  })
}

/**
 *  成套复制工艺卡片信息
 */
export function pasteCraftOperation(data) {
  return request({
    url: `/crafts/operation-card-sets`,
    method: 'post',
    data,
  })
}

/**
 *  删除分件卡片
 */
export function deletePartsCard(id) {
  return request({
    url: `/crafts/${id}`,
    method: 'delete',
  })
}

/**
 * 获取分件工艺导入模板
 */
export function getPartsCraftImportTemplate() {
  return request({
    url: `/crafts/parts/import-template`,
    method: 'get',
    responseType: 'blob',
  })
}

/**
 * 获取批量导入计划工艺模板
 */
export function getPlansCraftImportTemplate() {
  return request({
    url: `/crafts/plans/import-template`,
    method: 'get',
    responseType: 'blob',
  })
}

/**
 *  批量复制分件工艺卡片至主件
 */
export function batchPastePartsCraft(data) {
  return request({
    url: `/crafts/operation-cards/copy-parts`,
    method: 'post',
    data,
  })
}

/**
 *  根据计划id获取该计划下所有进行中的返修/协作任务信息
 */
export function getRepairTasks(planId) {
  return request({
    url: `/tasks/${planId}/repair-tasks`,
    method: 'get',
  })
}

/**
 * 新增任务工艺卡片
 */
export function postAddTaskCraftCard(data) {
  return request({
    url: `/crafts/tasks/operation-cards`,
    method: 'post',
    data,
  })
}

/**
 * 工艺特殊符号查询
 */
export function getCraftSymbols(data) {
  return request({
    url: `/parameters/craft-symbols`,
    method: 'get',
    data,
  })
}

/**
 * 实际工艺查询
 */
export function getTaskProcessRoute(planId) {
  return request({
    url: `/crafts/${planId}/tasks/actual-process-route`,
    method: 'get',
  })
}
