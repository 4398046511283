<template>
  <div class="app-container">
    <div class="top-tip">
      <div class="selected-wrapper">
        <span v-if="hasSelected">
          已选中
          <strong>{{ selectedRowKeys.length }}</strong> 项计划
        </span>
        <a-dropdown v-if="hasSelected">
          <a class="batch-operate-link" @click="e => e.preventDefault()">
            <a-icon type="edit" />批量操作
          </a>

          <a-menu slot="overlay">
            <a-menu-item>
              <a @click="handleCreateSaveInventoryCard(selectedRowKeys)">生成出库单</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </div>
    <a-select v-model="currentActiveType" v-if="selectVisible" class="type-select" @change="handleTypeChange">
      <a-select-option value="L">L计划</a-select-option>
      <a-select-option value="D">D计划</a-select-option>
      <a-select-option value="G">G计划</a-select-option>
      <a-select-option value="ALL">所有计划</a-select-option>
    </a-select>
    <a-tabs :active-key="currentActiveKey" type="card" @change="handleTabChange">
      <a-tab-pane key="0" tab="成品库房" />
      <a-tab-pane key="1" tab="半成品库房" />
      <a-tab-pane key="2" tab="工装库" />
      <a-tab-pane key="3" tab="材料库" />
      <a-tab-pane key="4" tab="废品库" />
      <a-tab-pane key="5" tab="来料退回库" />
      <a-tab-pane key="6" tab="所有库" />
    </a-tabs>
    <a-table :key="tableKey" row-key="id" bordered :scroll="{ x: 1700 }" :columns="inventoryColumns"
      :data-source="inventoryList"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, getCheckboxProps: inventoryRowSelection.getCheckboxProps }"
      :pagination="pagination" :loading="loading" @change="handleTableChange">
      <div slot="filterDropdown" slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
        style="padding: 8px">
        <template v-if="column.searchType === 'text'">
          <a-input v-ant-ref="c => (searchInput = c)" :placeholder="`搜索 ${column.title}`" :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)" />
        </template>
        <div v-else-if="column.searchType === 'number'">
          <a-input-number v-model="selectedKeys[0]" size="small" placeholder="最小值" :min="0" :max="999999"
            style="width: 95px; margin-bottom: 8px; display: inline-block;"
            @change="e => setSelectedKeys([e, selectedKeys[1]])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)" />
          <span>-</span>
          <a-input-number v-model="selectedKeys[1]" size="small" placeholder="最大值" :min="selectedKeys[0]" :max="999999"
            style="width: 95px; margin-bottom: 8px; display: inline-block;"
            @change="e => setSelectedKeys([selectedKeys[0], e])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.key)" />
        </div>
        <div v-else-if="column.searchType === 'date'">
          <a-range-picker size="small" style="width: 190px; margin-bottom: 8px;"
            :ranges="{ '今天': [$moment(), $moment()], '本月': [$moment().startOf('month'), $moment().endOf('month')] }"
            @change="(dates, dateStrings) => setSelectedKeys([...dateStrings])" />
        </div>
        <a-button type="primary" icon="search" size="small" style="width: 90px; margin-right: 8px"
          @click="() => handleSearch(selectedKeys, confirm, column.key)">搜索</a-button>
        <a-button size="small" style="width: 90px"
          @click="() => handleReset(selectedKeys, clearFilters, column.key)">重置</a-button>
      </div>
      <span slot="isParts" slot-scope="text">
        <a-tag v-if="text === 0" color="green">否</a-tag>
        <a-tag v-if="text === 1" color="red">是</a-tag>
      </span>
      <span slot="status" slot-scope="text">
        <a-tag v-if="text === 'NRM'" color="green">正常</a-tag>
        <a-tag v-if="text === 'OCN'" color="orange">出库单撤销</a-tag>
        <a-tag v-if="text === 'WOT'" color="blue">待出库</a-tag>
        <a-tag v-if="text === 'FOT'" color="red">已出库</a-tag>
      </span>
      <span slot="warehouseType" slot-scope="text">
        <a-tag v-if="text === 1" color="orange">半成品</a-tag>
        <a-tag v-if="text === 0" color="green">成品</a-tag>
        <a-tag v-if="text === 2" color="blue">工装</a-tag>
        <a-tag v-if="text === 4" color="red">废品</a-tag>
        <a-tag v-if="text === 5" color="red">来料退回库</a-tag>
        <a-tag v-if="text === 3" color="orange">材料</a-tag>
      </span>
      <template slot="oddNumbers" slot-scope="text, column">
        <template v-if="column.takeOutNumber">
          <el-link type="primary" @click="handleCheckInventory(column)">{{ text }}</el-link>
        </template>
        <template v-else>
          <el-link type="primary" @click="handleCreateSingleCard(column)">点击生成</el-link>
        </template>
      </template>
      <div slot="takeOutComment" slot-scope="text, column">
        <template v-if="commentEditId === column.id">
          <a-input style="width: 150px;margin-right: 10px;" :value="text"
            @blur="e => handleUpdateComment(e.target.value, column)" />
        </template>
        <a-tooltip v-else title="点击更新备注">
          <div style="cursor: pointer; width: 100%; height: 30px;" @click="handleEditComment(column.id)">
            <span>{{ text }}</span>
          </div>
        </a-tooltip>
      </div>
      <div slot="actualUseMaterialList" slot-scope="text">
        <a-tooltip>
          <template slot="title">
            <span v-for="(item, index) in text" :key="index">
              {{ item.materialName }}({{ item.materialCode }})
              <span v-if="index < text.length - 1">,</span>
            </span>
          </template>
          <div class="table-ellis">
            <span v-for="(item, index) in text" :key="index">{{ item.materialName }}({{ item.materialCode }}),</span>
          </div>
        </a-tooltip>
      </div>
      <a-icon slot="filterIcon" slot-scope="filtered" type="search"
        :style="{ color: filtered ? '#108ee9' : undefined }" />
      <div slot="operation" slot-scope="text, column" class="table-operation">
        <a-dropdown>
          <a-menu slot="overlay" class="ant-dropdown-link">
            <a-menu-item @click="handleBatchGoods(column)">出库分批</a-menu-item>
            <a-menu-item v-if="column.takeOutNumber && !column.invoiceNumber"
              @click="handleRevertModal(column)">撤销出库单</a-menu-item>
            <a-menu-item @click="handleCheckLog(column)">查看操作日志</a-menu-item>
          </a-menu>
          <a class="operation-btn">
            操作&nbsp;
            <a-icon type="down" />
          </a>
        </a-dropdown>
      </div>
    </a-table>
    <el-dropdown class="export-craft" @command="handleExportCommand">
      <el-button :loading="exportLoading" size="medium" type="primary" icon="el-icon-download">导出出库记录</el-button>
      <el-dropdown-menu slot="dropdown">
        <!-- <el-dropdown-item command="SELECTED">导出当前已选工艺</el-dropdown-item>
        <el-dropdown-item command="PAGE">导出当前页工艺</el-dropdown-item>-->
        <el-dropdown-item command="FILTERED">导出当前筛选记录</el-dropdown-item>
        <!-- <el-dropdown-item command="ALL">导出所有工艺</el-dropdown-item> -->
      </el-dropdown-menu>
    </el-dropdown>
    <CraftEditModal :modal-title="currentEditType" :craft-form="currentCraft" :visible="visible" />
    <ProcessRouteCard v-if="routeModalVisible" :modal-title="currentPlanCode" :record="currentProcessRoute"
      @cancel="handleRouteModalCanceled" @confirm="handleRouteModalConfirmed" />
    <CheckInventoryModal v-if="ifShowCheckInventoryModal" type="out" :inventory-data="checkInventoryColumn"
      @cancel="handleCheckInventoryCanceled" @confirm="handleCheckInventoryConfirmed" />
    <InventoryRecordsModal v-if="ifShowInventoryRecordsModal" type="out" :records-data="recordIds"
      @cancel="handleInventoryRecordsModalCanceled" @confirm="handleInventoryRecordsModalConfirmed" />
    <a-modal v-model="batchVisible" title="分批信息确认" @ok="handlebatchConfirm" @cancel="handlebatchCancel">
      <a-form-model ref="batchForm" :rules="batchRules" :model="batchForm" :label-col="batchForm.labelCol"
        :wrapper-col="batchForm.wrapperCol">
        <a-form-model-item ref="count" label="分批数量" prop="count">
          <a-input v-model="batchForm.count" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <InventoryReceiptModal v-if="receiptModalVisible" :id="receiptIds" type="out" @cancel="handleReceiptModalCanceled"
      @confirm="handleReceiptModalConfirmed" />
    <LogModal v-if="logModalVisible" :id="logId" type="OPC" @cancel="handleLogCanceled" />
    <RevertModal v-if="revertModalVisible" :record="revertRecord" @cancel="revertModalCancel"
      @confirm="revertModalConfirm" />
  </div>
</template>

<script>
import { deepClone } from '@/utils'
import * as R from 'ramda'
import { inventoryColumns, inventoryRowSelection } from './dataLogic'
import CraftEditModal from '@/components/CraftEditModal'
import CheckInventoryModal from '@/components/CheckInventoryModal'
import ProcessRouteCard from '@/components/ProcessRouteCard'
import InventoryRecordsModal from '@/components/InventoryRecordsModal'
import InventoryReceiptModal from '@/components/InventoryReceiptModal'
import { downloadItem } from '@/utils/api.request'
import {
  getOutInventorys,
  postBatchInventorys,
  exportOutInventory,
  updateRecordsComment,
  deleteOutInventorys
} from '@/api/inventory'
import LogModal from '@/components/LogModal'
import RevertModal from './components/revert-modal'

export default {
  components: {
    CraftEditModal,
    CheckInventoryModal,
    ProcessRouteCard,
    InventoryRecordsModal,
    InventoryReceiptModal,
    RevertModal,
    LogModal
  },
  data() {
    return {
      selectVisible: true,
      tableKey: 0,
      loading: false,
      inventoryList: [],
      inventoryColumns,
      inventoryRowSelection,
      selectedRowKeys: [],
      selectedRowPlanIds: [],
      searchInput: null,
      baseURL: '',
      fileURL: '',
      exportIds: [],
      pagination: {
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '20', '30', '40', '50'],
        showTotal: total => `共有 ${total} 条数据`,
        showQuickJumper: true
      },
      initInventoryListQueryParams: {
        pageNum: 1,
        pageSize: 10,
        recordWarehouseType: 'eq:0',
        'product.type': 'eq:L'
      },
      currentInventoryListQueryParams: {
        pageNum: 1,
        pageSize: 10,
        sort_by: null,
        recordWarehouseType: 'eq:0',
        'product.type': 'eq:L'
      },
      currentActiveType: 'L',
      currentActiveKey: '0',
      currentEditType: '更新',
      currentCraft: {},
      currentPlanCode: '',
      currentProcessRoute: [],
      visible: false,
      routeModalVisible: false,
      columnSearchParams: {},
      ifShowCheckInventoryModal: false,
      checkInventoryColumn: {},
      ifShowInventoryRecordsModal: false,
      recordIds: [],
      batchVisible: false,
      batchColumn: {},
      batchForm: {
        labelCol: { span: 4 },
        wrapperCol: { span: 14 },
        count: ''
      },

      batchRules: {
        count: [{ required: true, message: '请输入分批数量', trigger: 'blur' }]
      },
      exportLoading: false,
      receiptModalVisible: false,
      receiptIds: '',
      logModalVisible: false,
      logId: '',
      commentEditId: '',
      revertModalVisible: false,
      revertRecord: undefined,
    }
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0
    }
  },
  created() {
    // 初始化加载数据
    this.baseURL = process.env.VUE_APP_BASE_API
    const query = this.$route.query
    if (query.productName && query.productType) {
      this.currentActiveKey = query.productType
      this.currentInventoryListQueryParams['product.type'] =
        'eq:' + this.currentActiveKey
      this.currentInventoryListQueryParams.productName = `eq:${query.productName}`
      this.currentInventoryListQueryParams.drawingNum = `eq:${query.drawingNum}`
      this.currentInventoryListQueryParams.specification = `eq:${query.specification}`
    }
    this.getInventorys(this.currentInventoryListQueryParams)
  },
  methods: {
    handleTypeChange(activeType) {
      this.currentInventoryListQueryParams['product.type'] = 'eq:' + activeType
      if (activeType === 'ALL') {
        Reflect.deleteProperty(this.currentInventoryListQueryParams, 'product.type');
      }
      this.selectedRowKeys = []
      this.columnSearchParams = {}
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.getInventorys(this.currentInventoryListQueryParams)
      this.currentActiveType = activeType
      this.tableKey += 1
    },
    handleTabChange(activeKey) {
      if (activeKey === '6') {
        delete this.currentInventoryListQueryParams['recordWarehouseType']
      } else {
        this.currentInventoryListQueryParams['recordWarehouseType'] =
          'eq:' + activeKey
      }
      if (activeKey !== '0' && activeKey !== '6') {
        delete this.currentInventoryListQueryParams['product.type']
        this.selectVisible = false
      } else {
        this.currentInventoryListQueryParams['product.type'] =
          'eq:' + this.currentActiveType
        this.selectVisible = true
      }
      if (this.currentActiveType === 'ALL') {
        Reflect.deleteProperty(this.currentInventoryListQueryParams, 'product.type');
      }
      this.columnSearchParams = {}
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.currentInventoryListQueryParams.pageNum = 1
      this.getInventorys(this.currentInventoryListQueryParams)
      this.currentActiveKey = activeKey
      this.tableKey += 1
    },
    // 获取工艺数据
    getInventorys(data) {
      this.loading = true
      getOutInventorys(data)
        .then(res => {
          this.inventoryList = res.data.records
          this.pagination.total = res.data.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 根据当前分页数据和搜索条件查询数据
    getInventorysByParams() {
      const cQueryParams = deepClone(this.currentInventoryListQueryParams)
      const queryParams = Object.assign(cQueryParams, this.columnSearchParams)
      this.getInventorys(queryParams)
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.columnSearchParams[dataIndex] = ''
      if (selectedKeys && selectedKeys.length === 1) {
        if (selectedKeys[0].trim() !== '') {
          this.columnSearchParams[dataIndex] = 'like:' + selectedKeys[0].trim()
        }
      }
      if (selectedKeys && selectedKeys.length === 2) {
        if (selectedKeys[0] || selectedKeys[1]) {
          this.columnSearchParams[dataIndex] = 'btn:' + selectedKeys.toString()
        }
      }
    },
    // 重置搜索筛选条件
    handleReset(selectedKeys, clearFilters, dataIndex) {
      clearFilters()
      this.columnSearchParams[dataIndex] = null
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleTableChange(pagination, filters, sorter) {
      const filtersKeys = Object.keys(filters)
      console.log(filtersKeys)
      if (filtersKeys.includes('isParts')) {
        delete this.columnSearchParams['isParts']
        if (filters['isParts'].length > 0) {
          this.columnSearchParams['isParts'] = `in:${filters[
            'isParts'
          ].toString()}`
        } else {
          delete this.columnSearchParams['isParts']
        }
      }
      if (filtersKeys.includes('recordWarehouseType')) {
        delete this.columnSearchParams['recordWarehouseType']
        if (filters['recordWarehouseType'].length > 0) {
          this.columnSearchParams['recordWarehouseType'] = `in:${filters[
            'recordWarehouseType'
          ].toString()}`
        } else {
          delete this.columnSearchParams['recordWarehouseType']
        }
      }
      if (filtersKeys.includes('inOutRecord.status')) {
        delete this.columnSearchParams['inOutRecord.status']
        if (filters['inOutRecord.status'].length > 0) {
          this.columnSearchParams['inOutRecord.status'] = `in:${filters[
            'inOutRecord.status'
          ].toString()}`
        } else {
          delete this.columnSearchParams['inOutRecord.status']
        }
      }
      this.pagination = pagination
      this.currentInventoryListQueryParams.pageNum = pagination.current
      this.currentInventoryListQueryParams.pageSize = pagination.pageSize

      if (sorter.order) {
        const sortType = sorter.order === 'ascend' ? '+' : '-'
        this.currentInventoryListQueryParams.sort_by =
          sortType + sorter.columnKey
      } else {
        this.currentInventoryListQueryParams.sort_by = null
      }

      this.getInventorysByParams()
    },
    handleCheckInventory(column) {
      this.checkInventoryColumn = column
      this.ifShowCheckInventoryModal = true
    },
    handleCheckInventoryConfirmed() {
      this.checkInventoryColumn = {}
      this.ifShowCheckInventoryModal = false
      this.getInventorysByParams()
    },
    handleCheckInventoryCanceled() {
      this.checkInventoryColumn = {}
      this.ifShowCheckInventoryModal = false
    },
    handleCreateSingleCard(column) {
      this.recordIds = [column.id]
      this.ifShowInventoryRecordsModal = true
    },
    handleCreateSaveInventoryCard(selectedRowKeys) {
      this.recordIds = selectedRowKeys
      this.ifShowInventoryRecordsModal = true
    },
    handleInventoryRecordsModalCanceled() {
      this.ifShowInventoryRecordsModal = false
    },
    handleInventoryRecordsModalConfirmed() {
      this.recordIds = []
      this.selectedRowKeys = []
      this.ifShowInventoryRecordsModal = false
      this.getInventorysByParams()
    },
    handleBatchGoods(columns) {
      this.batchColumn = columns
      this.batchVisible = true
    },
    handlebatchConfirm() {
      this.$refs.batchForm.validate(valid => {
        if (valid) {
          const params = {
            id: this.batchColumn.id,
            count: this.batchForm.count
          }
          postBatchInventorys(params).then(res => {
            if (res.status === 200) {
              this.$message.success('分批成功')
              this.batchColumn = {}
              this.batchCount = ''
              this.batchVisible = false
              this.getInventorysByParams()
            } else {
              this.$message.error('分批失败')
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handlebatchCancel() {
      this.batchColumn = {}
      this.batchForm.count = ''
      this.batchVisible = false
    },
    handleExportCommand(command) {
      this.exportLoading = true
      switch (command) {
        case 'FILTERED':
          const cQueryParams = deepClone(this.currentInventoryListQueryParams)
          const queryParams = Object.assign(
            cQueryParams,
            this.columnSearchParams
          )
          exportOutInventory({
            ...queryParams
          })
            .then(res => {
              this.exportLoading = false
              this.download(res)
            })
            .finally(() => {
              this.exportLoading = false
            })
          break
        default:
          this.exportLoading = false
          break
      }
    },
    download(blobData) {
      const currentDate = +Date.now()
      downloadItem(
        blobData,
        'application/vnd.ms-excel',
        '出库记录表' + currentDate
      )
    },
    handleUpdateComment(value, column) {
      console.log(value, column)
      updateRecordsComment(column.id, {
        takeOutComment: value
      }).then(res => {
        this.$message.success('更新备注成功')
        this.commentEditId = null
        this.getInventorysByParams()
      })
    },
    handleEditComment(id) {
      this.commentEditId = id
    },
    handleInReceipts(column) {
      this.receiptModalVisible = true
      // this.receiptIds = column.id
      this.receiptIds = column.takeOutNumber
    },
    handleReceiptModalCanceled() {
      this.receiptModalVisible = false
    },
    handleReceiptModalConfirmed() {
      this.receiptModalVisible = false
      this.getInventorysByParams()
    },
    handleCheckLog(column) {
      this.logId = column.id
      this.logModalVisible = true
    },
    handleLogCanceled() {
      this.logModalVisible = false
    },
    handleRevertModal(column) {
      this.revertRecord = column
      this.revertModalVisible = true
    },
    revertModalCancel() {
      this.revertModalVisible = false
    },
    revertModalConfirm() {
      this.revertModalVisible = false
      this.getInventorysByParams()
    },
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  .file-wrapper {
    width: 140px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .filename-wrapper {
      display: inline-block;
      width: 120px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-right: 10px;

      a {
        color: #67c23a;
      }
    }

    .filename-delete {
      color: #ff4d4f;
      margin-right: 8px;
    }
  }

  .batch-operate-link {
    padding-left: 20px;
    cursor: pointer;
    color: #409eff;
  }

  .file-link {
    color: #409eff;
  }

  .craft-download-button {
    position: absolute;
    right: 20px;
    top: 70px;
  }

  .operation {
    .operation-btn {
      cursor: pointer;
      color: #409eff;
    }
  }

  .export-craft {
    float: right;
    top: -50px;
    right: 20px;
  }

  /deep/ .ant-table-pagination,
  .ant-pagination {
    float: left;
  }

  .type-select {
    z-index: 999;
    width: 100px;
    position: absolute;
    top: 72px;
    right: 20px;
  }

  .table-ellis {
    overflow: hidden; //超出的文本隐藏
    text-overflow: ellipsis; //溢出用省略号显示
    white-space: nowrap; //溢出不换行
  }
}
</style>
